import React, { useState } from "react";
import IsLoadingHOC from "../common/Loader/Loader";
import { toast } from "react-toastify";
import AuthInput from "components/CustomElement/AuthInput";
import Heading from "../CustomElement/Heading";
import ToggleButton from "components/CustomElement/ToggleButton";
import Select from "react-select";
import { userList } from "data";
const defaultObj = {
	name: "",
	description: "",
	isActive: true,
	memberList: []
};

const CreateModule = (props) => {
	const [formData, setFormData] = useState(defaultObj);
	const handleSubmit = async () => {
		props.setLoading(true);
		try {
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setFormData(defaultObj);
			toast.success("Successfully saved!");
		} catch (err) {
			toast.error(`Error in saving`);
		} finally {
			props.setLoading(false);
		}
	};

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	}

	const handleToggle = () => {
		setFormData({ ...formData, isActive: !formData.isActive });
	}

	return (
		<div className="w-full text-sm bg-white p-6 rounded shadow border ">
			<Heading text={'Create Group'} headingclass='text-xl' isButton={true} buttonText={'Create Now'} handleSubmit={handleSubmit} />
			<div className="grid md:grid-cols-3 sm:grid-cols-2 gap-8">
				<AuthInput label='Name' required={true} placeholder='Enter Name' name='name' maxLength={50} onChange={handleChange} value={formData.name} />
				<AuthInput label='Description' required={true} name='description' placeholder='Enter description' maxLength={80} onChange={handleChange} value={formData.description} />
				<ToggleButton handleToggle={handleToggle} isTrue={formData?.isActive} label='Active' />
			</div>
			<div>
				<Heading text='Add Member' headingclass='mt-6' />
				{
					<Select isMulti={true} options={userList.map(i => ({ label: i.Name, value: i.ID }))} value={formData.memberList} onChange={(e) => setFormData({ ...formData, memberList: e })} />
				}
			</div>
		</div>
	);
};

export default IsLoadingHOC(CreateModule, "Please Wait...");