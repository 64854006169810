import React, { Children, useEffect, useMemo } from 'react'
import { moduleDBList } from 'data'
import { Link } from 'react-router-dom';
import AuthInput from 'components/CustomElement/AuthInput';
import Button from "components/CustomElement/Button";
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { userGroups } from 'data';
import Heading from 'components/CustomElement/Heading';
import { toast } from 'react-toastify';
import IsLoadingHOC from 'components/common/Loader/Loader';
import { userList } from 'data';
import ToggleButton from 'components/CustomElement/ToggleButton';
const EditGroup = (props) => {
  const [payload, setPayload] = React.useState({});
  const [activeEdit, setActiveEdit] = React.useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const edit = queryParams.get('edit');
  useEffect(() => {
    if (edit) {
      let foundChild = userGroups?.find(v => v.ID === edit);
      setPayload(foundChild);
      setActiveEdit(foundChild);
    }
  }, [edit]);


  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  }

  const handleWorkFlow = (workFlow) => {
    setPayload(workFlow);
    setActiveEdit(workFlow);
  }

  const handleSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      toast.success("Successfully saved!");
    } catch (err) {
      toast.error(`Error in saving`);
    } finally {

    }
  };

  const handleToggle = () => {
    setPayload({ ...payload, isActive: !payload.isActive });
  }

  return (
    <div className='bg-white p-6 w-full'>
      <div className='w-full '>
        {
          !Object.keys(activeEdit ?? {}).length > 0 ?
            <div className='mb-12'>
              <Heading text='Edit Groups' />
              <div className=' mt-2 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 place-items-start gap-4 w-full ' >
                {
                  (userGroups ?? [])?.map((v, i) => (
                    <Link to={window.location.pathname + `?edit=` + v.ID} key={v.ID} onClick={() => handleWorkFlow(v)} className='border md:h-48 md:w-48 w-full h-48 rounded flex items-center justify-center cursor-pointer bg-white shadow border-[#2b4eff5c] '>
                      <h1 className='text-center text-xs'>{v.name}</h1>
                    </Link>
                  ))
                }
              </div>
            </div>
            : <div className="w-full text-sm bg-white p-6 rounded shadow border ">
              <Heading text={'Update Group'} headingclass='text-xl' isButton={true} buttonText={'Update Now'} handleSubmit={handleSubmit} />
              <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-8">
                <AuthInput label='Name' required={true} placeholder='Enter Name' name='name' maxLength={50} onChange={handleChange} value={payload.name} />
                <AuthInput label='Description' required={true} name='description' placeholder='Enter description' maxLength={80} onChange={handleChange} value={payload.description} />
                <ToggleButton handleToggle={handleToggle} isTrue={payload?.isActive} label='Active' />
              </div>
              <div>
                <Heading text='Added Members' headingclass='mt-6' />
                {
                  <Select isMulti={true} options={userList.map(i => ({ label: i.name, value: i.ID }))} value={payload.memberList} onChange={(e) => setPayload({ ...payload, memberList: e })} />
                }
              </div>
            </div>
        }
      </div>
    </div >
  )
}

export default IsLoadingHOC(EditGroup, "Please Wait...");