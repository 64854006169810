export * from "./itemMaster";
export * from "./AggridComp"


export const moduleDBList = [
    {
        "ID": "f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d",
        "Name": "Administration",
        "UnderID": null,
        "Sequence": 1,
        "link": "/administration",
        "linkVal": "Administration",
        "inactive": 0,
        "type": 1,
        "ChildList": [
            {
                "ID": "a1b2c3d4-e5f6-7a8b-9c0d-1e2f3b4c5d6e",
                "Name": "Module Management",
                "UnderID": "f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d",
                "Sequence": 1,
                "link": "/module-management",
                "linkVal": "ModuleManagement",
                "ChildList": [
                    {
                        "ID": "b1c2d3e4-f5a6-7b8c-9d0e-1f2b3c4d5e6f",
                        "Name": "Create Module",
                        "UnderID": "a1b2c3d4-e5f6-7a8b-9c0d-1e2f3b4c5d6e",
                        "Sequence": 1,
                        "link": "/create",
                        "linkVal": "CreateModule",
                        "inactive": 0,
                        "type": 3
                    },
                    {
                        "ID": "c1d2e3f4-a5b6-7c8d-9e0f-1b2c3d4e5f6a",
                        "Name": "Edit Module",
                        "UnderID": "a1b2c3d4-e5f6-7a8b-9c0d-1e2f3b4c5d6e",
                        "Sequence": 2,
                        "link": "/edit",
                        "linkVal": "EditModule",
                        "inactive": 0,
                        "type": 3
                    }
                ],
                "inactive": 0,
                "type": 2
            },
            // Group Management
            {
                "ID": "d1e2f3a4-b5c6-7d8e-9f0a-1c2d3e4f5b6a",
                "Name": "Group Management",
                "UnderID": "f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d",
                "Sequence": 2,
                "link": "/group-management",
                "linkVal": "GroupManagement",
                "ChildList": [
                    {
                        "ID": "e1f2a3b4-c5d6-7e8f-9a0b-1d2c3e4f5a6b",
                        "Name": "Create Group",
                        "UnderID": "d1e2f3a4-b5c6-7d8e-9f0a-1c2d3e4f5b6a",
                        "Sequence": 1,
                        "link": "/create",
                        "linkVal": "CreateGroup",
                        "inactive": 0,
                        "type": 3
                    },
                    {
                        "ID": "f1a2b3c4-d5e6-7f8a-9b0c-1e2d3c4f5a6b",
                        "Name": "Edit Group",
                        "UnderID": "d1e2f3a4-b5c6-7d8e-9f0a-1c2d3e4f5b6a",
                        "Sequence": 2,
                        "link": "/edit",
                        "linkVal": "EditGroup",
                        "inactive": 0,
                        "type": 3
                    }
                ],
                "inactive": 0,
                "type": 2
            },
            // User Management
            {
                "ID": "a2b3c4d5-e6f7-8a9b-0c1d-2e3f4b5c6d7a",
                "Name": "User Management",
                "UnderID": "f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d",
                "Sequence": 3,
                "link": "/user-management",
                "linkVal": "UserManagement",
                "ChildList": [
                    {
                        "ID": "b2c3d4e5-f6a7-8b9c-0d1e-2f3b4c5d6e7f",
                        "Name": "Create User",
                        "UnderID": "a2b3c4d5-e6f7-8a9b-0c1d-2e3f4b5c6d7a",
                        "Sequence": 1,
                        "link": "/create",
                        "linkVal": "CreateUser",
                        "inactive": 0,
                        "type": 3
                    },
                    {
                        "ID": "c2d3e4f5-a6b7-8c9d-0e1f-2b3c4d5e6f7a",
                        "Name": "Edit User",
                        "UnderID": "a2b3c4d5-e6f7-8a9b-0c1d-2e3f4b5c6d7a",
                        "Sequence": 2,
                        "link": "/edit",
                        "linkVal": "EditUser",
                        "inactive": 0,
                        "type": 3
                    }
                ],
                "inactive": 0,
                "type": 2
            },
            {
                "ID": "a2b3c4d5-e6f7-8a9b-0c1d-2e3f4b5c6d7a",
                "Name": "Permission Management",
                "UnderID": "f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d",
                "Sequence": 3,
                "link": "/permission-management",
                "linkVal": "PermissionManagement",
                "inactive": 0,
                "type": 2
            }

        ]
    },
    {
        "ID": "j4k5l6m7-n8o9-40p1-q2r3-s4t5u6v7w8x9",
        "Name": "Advertisement Management",
        "UnderID": null,
        "Sequence": 3,
        "link": "/advertisement-management",
        "linkVal": "AdvertisementManagement",
        "inactive": 0,
        "type": 1,
        "ChildList": [
            {
                "ID": "k5l6m7n8-o9p0-41q2-r3s4-t5u6v7w8x9y0",
                "Name": "Create Advertisement",
                "UnderID": "j4k5l6m7-n8o9-40p1-q2r3-s4t5u6v7w8x9",
                "Sequence": 1,
                "link": "/create-advertisement",
                "linkVal": "CreateAdvertisement",
                "inactive": 0,
                "type": 3
            },
            {
                "ID": "l6m7n8o9-p0q1-42r3-s4t5-u6v7w8x9y0z1",
                "Name": "Manage Advertisement",
                "UnderID": "j4k5l6m7-n8o9-40p1-q2r3-s4t5u6v7w8x9",
                "Sequence": 2,
                "link": "/manage-advertisement",
                "linkVal": "ManageAdvertisement",
                "inactive": 0,
                "type": 3
            }
        ]
    },
    {
        "ID": "d7e8f9a1-b2c3-44d5-8e9f-0a1b2c3d4e5f",
        "Name": "User Management",
        "UnderID": null,
        "Sequence": 2,
        "link": "/user-management",
        "linkVal": "UserManagement",
        "inactive": 0,
        "type": 1,
        "ChildList": [
            {
                "ID": "e6f7a8b9-c0d1-42e3-f4b5c6d7e8f9",
                "Name": "Journalist Management",
                "UnderID": "d7e8f9a1-b2c3-44d5-8e9f-0a1b2c3d4e5f",
                "Sequence": 1,
                "link": "/journalist-management",
                "linkVal": "JournalistManagement",
                "inactive": 0,
                "type": 2,
                "ChildList": [
                    {
                        "ID": "f5a6b7c8-d9e0-41f2-b3c4d5e6f7a8",
                        "Name": "Add Journalist",
                        "UnderID": "e6f7a8b9-c0d1-42e3-f4b5c6d7e8f9",
                        "Sequence": 1,
                        "link": "/add-journalist",
                        "linkVal": "AddJournalist",
                        "inactive": 0,
                        "type": 3
                    },
                    {
                        "ID": "g1h2i3j4-k5l6-47m8-n9o0-p1q2r3s4t5u6",
                        "Name": "Edit Journalist",
                        "UnderID": "e6f7a8b9-c0d1-42e3-f4b5c6d7e8f9",
                        "Sequence": 2,
                        "link": "/edit-journalist",
                        "linkVal": "EditJournalist",
                        "inactive": 0,
                        "type": 3
                    }
                ]
            },
            {
                "ID": "h2i3j4k5-l6m7-49n0-o1p2-q3r4s5t6u7v8",
                "Name": "Reader Management",
                "UnderID": "d7e8f9a1-b2c3-44d5-8e9f-0a1b2c3d4e5f",
                "Sequence": 2,
                "link": "/reader-management",
                "linkVal": "ReaderManagement",
                "inactive": 0,
                "type": 2,
                "ChildList": [
                    {
                        "ID": "i3j4k5l6-m7n8-41o9-p0q1-r2s3t4u5v6w7",
                        "Name": "Manage Subscriptions",
                        "UnderID": "h2i3j4k5-l6m7-49n0-o1p2-q3r4s5t6u7v8",
                        "Sequence": 1,
                        "link": "/manage-subscriptions",
                        "linkVal": "ManageSubscriptions",
                        "inactive": 0,
                        "type": 3
                    }
                ]
            }
        ]
    },
    {
        "ID": "b3f2e5a4-d1c6-47f9-a9d2-3f4b5c6d7e8f",
        "Name": "Content Management",
        "UnderID": null,
        "Sequence": 1,
        "link": "/content-management",
        "linkVal": "ContentManagement",
        "inactive": 0,
        "type": 1,
        "ChildList": [
            {
                "ID": "a6d8e9f1-b2c3-45d7-8e9f-0a1b2c3d4e5f",
                "Name": "Create Article",
                "UnderID": "b3f2e5a4-d1c6-47f9-a9d2-3f4b5c6d7e8f",
                "Sequence": 1,
                "link": "/create-article",
                "linkVal": "CreateArticle",
                "inactive": 0,
                "type": 3
            },
            {
                "ID": "c4d5e6f7-a8b9-40c1-d2e3f4b5c6d7",
                "Name": "Edit Article",
                "UnderID": "b3f2e5a4-d1c6-47f9-a9d2-3f4b5c6d7e8f",
                "Sequence": 2,
                "link": "/edit-article",
                "linkVal": "EditArticle",
                "inactive": 0,
                "type": 3
            }
        ]
    },
]

export const userGroups = [
    {
        "ID": "550e8400-e29b-41d4-a716-446655440001",
        "Name": "Editors",
        "description": "Users who can edit and publish articles",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true,
        "memberList": [
            { label: "Shashi Kant", value: "13cce3f4-48e6-4727-9b89-38405a536e4c" },
            { label: "Vikash Kumar", value: "a1f5b6a7-3c3a-45e5-b9e7-12d0a5b6e09d" }
        ]
    },
    {
        "ID": "660e8500-f29c-42d5-b817-556766550002",
        "Name": "Reporters",
        "description": "Users who can create and submit articles for review",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true,
        "memberList": [
            { label: "Shashi Kant", value: "13cce3f4-48e6-4727-9b89-38405a536e4c" },
            { label: "Vikash Kumar", value: "a1f5b6a7-3c3a-45e5-b9e7-12d0a5b6e09d" },
            { label: "Mohammed Akash", value: "a1f5b6a7-3c3a-45e5-b9e7-12d0a5b6e09k" }
        ]
    },
    {
        "ID": "770f8600-g39d-53e6-c928-667877660003",
        "Name": "Administrators",
        "description": "Users who have full control over the system",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true,
        "memberList": [
            { label: "Shashi Kant", value: "13cce3f4-48e6-4727-9b89-38405a536e4c" },
            { label: "Vikash Kumar", value: "a1f5b6a7-3c3a-45e5-b9e7-12d0a5b6e09f" },
            { label: "Tarushi Chaudhary", value: "a1f5b6a7-3c3a-45e5-b9e7-12d0a5b6e09d" }
        ]
    },
    {
        "ID": "880f8700-h40e-64f7-d039-778988770004",
        "Name": "Business Analysts",
        "description": "Users who analyze and provide insights on business and market trends",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true,
        "memberList": [
            { label: "Shashi Kant", value: "13cce3f4-48e6-4727-9b89-38405a536e4c" }
        ]
    },
    {
        "ID": "990g8800-i50f-75g8-e149-889099880005",
        "Name": "Web Developers",
        "description": "Users responsible for developing and maintaining the web application",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true,
        "memberList": [
            { label: "Vikash Kumar", value: "a1f5b6a7-3c3a-45e5-b9e7-12d0a5b6e09d" }
        ]
    },
    {
        "ID": "aa0h9900-j60g-86h9-f259-990110990006",
        "Name": "Content Writers",
        "description": "Users responsible for writing and curating content",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true,
        "memberList": [
            { label: "Mohammed Akash", value: "a1f5b6a7-3c3a-45e5-b9e7-12d0a5b6e09k" }
        ]
    },
    {
        "ID": "bb1i0010-k70h-97i0-g360-111221000007",
        "Name": "Photographers",
        "description": "Users responsible for capturing images for news articles",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true,
        "memberList": [
            { label: "Rajesh Sharma", value: "c2d5e7f4-48e6-4727-9b89-38405a536f7x" }
        ]
    },
    {
        "ID": "cc2j1120-l80i-08j1-h470-222332111008",
        "Name": "Video Editors",
        "description": "Users responsible for editing and managing video content",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true,
        "memberList": [
            { label: "Anjali Verma", value: "d3e6f8g5-48e6-4727-9b89-38405a536h8y" }
        ]
    },
    {
        "ID": "dd3k2230-m90j-19k2-i580-333443222009",
        "Name": "Social Media Managers",
        "description": "Users responsible for managing social media platforms",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true,
        "memberList": [
            { label: "Pooja Mehta", value: "e4f7g9h6-48e6-4727-9b89-38405a536i9z" }
        ]
    },
    {
        "ID": "ee4l3340-n01k-20l3-j690-444554333010",
        "Name": "Graphic Designers",
        "description": "Users responsible for designing graphics and visual elements",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true,
        "memberList": [
            { label: "Rahul Sen", value: "f5h8i0j7-48e6-4727-9b89-38405a536j0a" }
        ]
    }
];


export const userList = [
    {
        "ID": "13cce3f4-48e6-4727-9b89-38405a536e4c",
        "salutation": "Mr",
        "designation": "Business Analyst",
        "designationId": "6cd3556d-dc3b-4a1e-9b56-b1c1f7f9a098",
        "Name": "Shashi Kant",
        "username": "shashi",
        "email": "shashi892.kant@gmail.com",
        "about": "With over 13 years of dedicated expertise in business analysis, I bring a wealth of knowledge and skills honed through hands-on experience across diverse industries. My career is distinguished by a proven track record of driving business transformation and optimizing operational efficiency through insightful analysis, strategic planning, and implementation of robust solutions. I am committed to leveraging data-driven insights to empower organizations, streamline processes, and achieve sustainable growth. As a seasoned professional, I thrive on solving complex challenges and delivering measurable results that align with business objectives.",
        "description": "A profund Data Analyst | Aspiring Data Scientist",
        "phone": "9354824403",
        "socialLinks": [
            {
                "ID": "7faaf337-66b1-4f5f-bd93-c8f9c34e8cde",
                "Name": "LinkedIn",
                'image': "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-linkedin-1024.png",
                "url": "https://www.linkedin.com/in/shashi-kant-779124216"
            },
            {
                "ID": "6b2f4e6b-97a0-4b3a-a5e8-25f3d6978f6d",
                "Name": "GitHub",
                'image': "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-social-github-1024.png",
                "url": "https://github.com/shashi"
            },
            {
                "ID": "e5a87d5d-ffcd-4d1e-b77b-c2745a4db8e8",
                "Name": "Twitter",
                'image': "https://cdn2.iconfinder.com/data/icons/threads-by-instagram/24/x-logo-twitter-new-brand-contained-1024.png",
                "url": "https://twitter.com/shashikant"
            }
        ],
        "experience": [
            {
                "ID": "1c43b49a-fb7a-44e3-9a4e-f5c5a3a5b35c",
                "title": "Senior Business Analyst",
                "company": "Zadley India Pvt. Ltd.",
                "duration": "Sep 2022 - Present",
                'avatar': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHATIwrqZXCYAMXolUAiD9EmGpuGzxPsS5WQ&s',
                "description": "Play a pivotal role in the success of our organization by serving as the bridge between our business objectives and the technology solutions we implement."
            },
            {
                "ID": "2a5c7c9e-fc49-4181-b5c6-8cb1b7a4c319",
                "title": "Data Analyst",
                "company": "India Mart",
                'avatar': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2N8HoZbLLaAkmXzv6gjwiED6r1uZFx4mf5A&s',
                "duration": "Jun 2019 - Dec 2022",
                "description": "Conduct thorough analysis of current business processes and systems to identify opportunities for improvement. Gather and document business requirements from stakeholders across different functional areas."
            }
        ],
        "education": [
            {
                "ID": "b1c95f6a-839c-4e7e-96ea-b0f5d9a5e4f4",
                "degree": "BS in IT",
                "institution": "Kuvempu University",
                'avatar': 'https://pbs.twimg.com/profile_images/1324254773940383750/CGC-lEpW_400x400.jpg',
                "duration": "Feb 2023 - May 2024",
            },
            {
                "ID": "d2b97e4b-5a2e-4c8e-a0b2-f2a6d4e7a3e1",
                "degree": "InterMediate",
                "institution": "K.S.S College",
                'avatar': 'https://www.static-contents.youth4work.com/university/Documents/Colleges/collegeLogo/20191106163638.png?v=20191106163638',
                "duration": "Feb 2023 - May 2024",
            },
            {
                "ID": "6e8c37a5-bb79-4b2b-9e4a-82e8a6e5b7f5",
                "degree": "High School",
                "institution": "Central School",
                'avatar': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSckqFLpZfGsLkrf9MziqHnggEINKPztm2zRA&s',
                "duration": "Feb 2023 - May 2024",
            }
        ],
        "skills": [
            {
                "ID": "f6c97a5e-bc69-4e3a-a7e3-7c7b5f6d8a3b",
                "skill": "BigQuery"
            },
            {
                "ID": "b7e94a3f-5a3d-4f7e-8c9a-7e8c5d6a9a4f",
                "skill": "MS Sql"
            },
            {
                "ID": "e8b6f5c3-5e9a-45d4-8b9c-b6c3e5d8a9e6",
                "skill": "Python"
            },
            {
                "ID": "9e3c87a9-2a5e-4e8c-9b6a-d5f7b8e7c3d5",
                "skill": "Tableau"
            },
            {
                "ID": "b3e5a8e4-9e8f-4b5e-8b6c-8d7f6a9b8c7e",
                "skill": "Power BI"
            },
            {
                "ID": "7f6a3e9c-5c6b-4e8e-9d5b-8b3e9d6a8c4e",
                "skill": "Power Query"
            },
            {
                "ID": "b9e7f5d4-3a6e-4c8f-9a7d-8e5c7b9d8c3f",
                "skill": "Advanced Excel"
            },
            {
                "ID": "e5a8d4c3-5b7f-4e9d-8c6a-7d8f6b9e3c4d",
                "skill": "Data Studio"
            },
            {
                "ID": "9b7e6d5a-5f4c-4e8e-8b3a-d7c8e5a7b3c9",
                "skill": "Git"
            },
            {
                "ID": "f7e6a4d3-3b5c-4e9e-8b6d-8c3f6a9e8b4d",
                "skill": "REST APIs"
            }
        ],
        "address": "B-336/A,New Ashok Nagar",
        "state": "Delhi",
        "country": "India",
        "zipCode": "110096",
        "city": "noida",
        "avatar": "https://res-console.cloudinary.com/dn83xtspp/thumbnails/v1/image/upload/v1720700029/V2hhdHNBcHBfSW1hZ2VfMjAyNC0wNy0xMV9hdF81LjMxLjI5X1BNXzFfbHd2c2tn/drilldown",
        "resume": "https://example.com/resume.pdf",
        "dateOfBirth": "1989-04-19"
    },
    {
        "ID": "a1f5b6a7-3c3a-45e5-b9e7-12d0a5b6e09f",
        "salutation": "Mr",
        "designation": "Web Developer",
        "designationId": "e6178e46-6d3b-4d8b-a8e7-0e8e3a9d64e1",
        "Name": "Vikash Kumar",
        "username": "vikas",
        "email": "vs936836@gmail.com",
        "about": "I am a Full Stack Developer from Delhi with 2 years of experience. My expertise spans both front-end and back-end development, making me versatile in creating comprehensive web solutions.",
        "description": "A Profound Web Developer | Aspiring Engineer",
        "phone": "9368361504",
        "socialLinks": [
            {
                "ID": "bb7a8f3e-cabc-4c44-82ed-1b3574a85c1d",
                "Name": "LinkedIn",
                'image': "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-linkedin-1024.png",
                "url": "https://www.linkedin.com/in/vikash-kumar-97a55122b"
            },
            {
                "ID": "ff647e3e-9427-4a2c-9d88-f8ebc0d39191",
                "Name": "GitHub",
                'image': "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-social-github-1024.png",
                "url": "https://github.com/vikassingh2410"
            },
            {
                "ID": "f57db7b7-cc33-4f6d-8e6b-cd4c4b7129c8",
                "Name": "Twitter",
                'image': "https://cdn2.iconfinder.com/data/icons/threads-by-instagram/24/x-logo-twitter-new-brand-contained-1024.png",
                "url": "https://twitter.com/vikassingh_2430"
            }
        ],
        "experience": [
            {
                "ID": "9f22b3b2-3a3e-48d6-a977-12c7a8b0b9b7",
                "title": "Web Developer",
                "company": "Zadley India Pvt. Ltd.",
                "duration": "May 2024 - Present",
                'avatar': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHATIwrqZXCYAMXolUAiD9EmGpuGzxPsS5WQ&s',
                "description": "play a pivotal role in the success of our organization by serving as the bridge between our business objectives and the technology solutions we implement."
            },
            {
                "ID": "e7a8c9c7-f5f4-4b6e-9d32-7b1e8f3d9c5e",
                "title": "Accountant",
                "company": "Indiamart Pvt Ltd.",
                "duration": "Feb 2023 - May 2024",
                'avatar': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2N8HoZbLLaAkmXzv6gjwiED6r1uZFx4mf5A&s',
                "description": "Conduct thorough analysis of current business processes and systems to identify opportunities for improvement. Gather and document business requirements from stakeholders across different functional areas."
            }
        ],
        "education": [
            {
                "ID": "f8e9c8a7-7a7d-4f6e-9b8c-3f7a5c9d7b3e",
                "degree": "BCA",
                'avatar': 'https://amityonline.com/_s/amity_logo_5ee70d67b4_f4e929fb4a_df29242913.svg',
                "duration": "May 2023 - present",
                "institution": "Amity University",
            },
            {
                "ID": "f8e9c8a7-7a7d-4f6e-9b8c-3f7a5c9d7b3e",
                "degree": "InterMediate",
                "institution": "G.G.S Public School",
                'avatar': 'https://www.ggspublicschool.in/images/about/about-2.jpg',
                "duration": "April 2016 - 2018",
            },
            {
                "ID": "b7e8f3a7-4f4d-4b7e-9b6c-8d7f7b9e8c5a",
                "degree": "High School",
                "institution": "G.G.S Public School",
                'avatar': 'https://www.ggspublicschool.in/images/about/about-2.jpg',
                "duration": "April 2014 - 2016",
            }
        ],
        "skills": [
            {
                "ID": "a8c9e8b8-4d4a-4f7e-9b7e-1a7d8f9b7c9d",
                "skill": "HTML"
            },
            {
                "ID": "c7b8e3a3-5f4f-4b8e-9d8c-9e7d6f8b8c3e",
                "skill": "MS Sql"
            },
            {
                "ID": "e9a7b9c7-7d7a-4c8e-9f6b-3d7b8f9e8c5a",
                "skill": "CSS"
            },
            {
                "ID": "d7a9e3c3-9f8d-4b9e-7f6a-8c7e6f9b7c3d",
                "skill": "Tailwind"
            },
            {
                "ID": "b9c7a8a7-5a5d-4f7e-9e6c-1f7d8e9b7c8a",
                "skill": "Javascript"
            },
            {
                "ID": "e3a9c8e7-4f4d-4f7e-9c8b-7a6d5f8e7c3a",
                "skill": "Reactjs"
            },
            {
                "ID": "a9c8b7e7-5d5a-4b8e-9f7c-8d7f5e9b7c8d",
                "skill": "Excel"
            },
            {
                "ID": "d8a7c8e8-7a7d-4b9e-9c6a-3f7e6d8b9c5a",
                "skill": "Node.js"
            },
            {
                "ID": "c7e8f3a3-4d4a-4f7e-9d6b-9e8f7b9d8c3e",
                "skill": "Git"
            },
            {
                "ID": "b7a8e7c8-5d5a-4f8e-9b7c-8d6e7f8b9c5a",
                "skill": "REST APIs"
            }
        ],
        "address": "Wow homes pg , Mamura , sector 66 , noida",
        "state": "Uttar Pradesh",
        "country": "India",
        "city": "noida",
        "zipCode": "201301",
        "avatar": "https://media.licdn.com/dms/image/D4D03AQHNeXIPEx4oiw/profile-displayphoto-shrink_400_400/0/1719220807255?e=1726099200&v=beta&t=GzeMdnb2KJnGLFqiIHejPKU5gjSJ61bofblj6hTtlMw",
        "resume": "https://drive.google.com/file/d/1ngQHXUrrC74WxGXhiCOZ8AmSTSm0Pzu6/view",
        "dateOfBirth": "2000-10-24"
    },
    {
        "ID": "a1f5b6a7-3c3a-45e5-b9e7-12d0a5b6e09d",
        "salutation": "Mrs",
        "designation": "Web Developer",
        "designationId": "e6178e46-6d3b-4d8b-a8e7-0e8e3a9d64e1",
        "Name": "Tarushi Chaudhary",
        "username": "tarushi",
        "email": "tarushic362@gmail.com",
        "about": "I am a Full Stack Developer from Delhi with 2 years of experience. My expertise spans both front-end and back-end development, making me versatile in creating comprehensive web solutions.",
        "description": "A Profound Web Developer | Aspiring Engineer",
        "phone": "9368361504",
        "socialLinks": [
            {
                "ID": "bb7a8f3e-cabc-4c44-82ed-1b3574a85c1d",
                "Name": "LinkedIn",
                'image': "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-linkedin-1024.png",
                "url": "https://www.linkedin.com/in/vikash-kumar-97a55122b"
            },
            {
                "ID": "ff647e3e-9427-4a2c-9d88-f8ebc0d39191",
                "Name": "GitHub",
                'image': "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-social-github-1024.png",
                "url": "https://github.com/vikassingh2410"
            },
            {
                "ID": "f57db7b7-cc33-4f6d-8e6b-cd4c4b7129c8",
                "Name": "Twitter",
                'image': "https://cdn2.iconfinder.com/data/icons/threads-by-instagram/24/x-logo-twitter-new-brand-contained-1024.png",
                "url": "https://twitter.com/vikassingh_2430"
            }
        ],
        "experience": [
            {
                "ID": "9f22b3b2-3a3e-48d6-a977-12c7a8b0b9b7",
                "title": "Intern",
                "company": "Techahead Corp.",
                "duration": "July 2024 - Present",
                'avatar': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHATIwrqZXCYAMXolUAiD9EmGpuGzxPsS5WQ&s',
                "description": "play a pivotal role in the success of our organization by serving as the bridge between our business objectives and the technology solutions we implement."
            },

        ],
        "education": [
            {
                "ID": "f8e9c8a7-7a7d-4f6e-9b8c-3f7a5c9d7b3e",
                "degree": "BCA",
                'avatar': 'https://amityonline.com/_s/amity_logo_5ee70d67b4_f4e929fb4a_df29242913.svg',
                "duration": "2022 - present",
                "institution": "CCS University",
            },
            {
                "ID": "f8e9c8a7-7a7d-4f6e-9b8c-3f7a5c9d7b3e",
                "degree": "InterMediate",
                "institution": "B K Academy",
                'avatar': 'https://www.ggspublicschool.in/images/about/about-2.jpg',
                "duration": "2020 - 2022",
            },
            {
                "ID": "b7e8f3a7-4f4d-4b7e-9b6c-8d7f7b9e8c5a",
                "degree": "High School",
                "institution": "S.C.M Public School",
                'avatar': 'https://www.ggspublicschool.in/images/about/about-2.jpg',
                "duration": "2018 - 2020",
            }
        ],
        "skills": [
            {
                "ID": "a8c9e8b8-4d4a-4f7e-9b7e-1a7d8f9b7c9d",
                "skill": "HTML"
            },
            {
                "ID": "c7b8e3a3-5f4f-4b8e-9d8c-9e7d6f8b8c3e",
                "skill": "MS Sql"
            },
            {
                "ID": "e9a7b9c7-7d7a-4c8e-9f6b-3d7b8f9e8c5a",
                "skill": "CSS"
            },
            {
                "ID": "d7a9e3c3-9f8d-4b9e-7f6a-8c7e6f9b7c3d",
                "skill": "Tailwind"
            },
            {
                "ID": "b9c7a8a7-5a5d-4f7e-9e6c-1f7d8e9b7c8a",
                "skill": "Javascript"
            },
            {
                "ID": "e3a9c8e7-4f4d-4f7e-9c8b-7a6d5f8e7c3a",
                "skill": "Reactjs"
            },
            {
                "ID": "a9c8b7e7-5d5a-4b8e-9f7c-8d7f5e9b7c8d",
                "skill": "Excel"
            },
            {
                "ID": "d8a7c8e8-7a7d-4b9e-9c6a-3f7e6d8b9c5a",
                "skill": "Node.js"
            },
            {
                "ID": "c7e8f3a3-4d4a-4f7e-9d6b-9e8f7b9d8c3e",
                "skill": "Git"
            },
            {
                "ID": "b7a8e7c8-5d5a-4f8e-9b7c-8d6e7f8b9c5a",
                "skill": "REST APIs"
            }
        ],
        "address": "Wow homes pg , Mamura , sector 66 , noida",
        "state": "Uttar Pradesh",
        "country": "India",
        "city": "noida",
        "zipCode": "201301",
        "avatar": "",
        "resume": "https://drive.google.com/file/d/17d4ioJ9SPYo3-nE8zr-Ql37PSJ2lmObW/view?usp=drivesdk",
        "dateOfBirth": "2004-07-31"
    },
    {
        "ID": "a1f5b6a7-3c3a-45e5-b9e7-12d0a5b6e09k",
        "salutation": "Mr",
        "designation": "Software Developer",
        "designationId": "e6178e46-6d3b-4d8b-a8e7-0e8e3a9d64e1",
        "Name": "Mohammed Akash",
        "username": "akash",
        "email": "mdakash2003evergreen@gmail.com",
        "about": "Hello, I'm Mohammed Akash, a Full Stack Developer and Designer with over 1.5+ years of experience creating impactful digital experiences.",
        "description": "Software Developer @ZADLEY | Salesforce | UI/ UX Developer | Content Creator",
        "phone": "7029793127",
        "socialLinks": [
            {
                "ID": "bb7a8f3e-cabc-4c44-82ed-1b3574a85c1d",
                "Name": "LinkedIn",
                'image': "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-linkedin-1024.png",
                "url": "https://www.linkedin.com/in/mohammed-akash/"
            },
            {
                "ID": "bb7a8f3e-cabc-4c44-82ed-1b3574a85c1d",
                "Name": "Youtube",
                'image': "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Youtube_colored_svg-1024.png",
                "url": "https://www.youtube.com/@mohammed-akash"
            }
        ],
        "experience": [
            {
                "ID": "9f22b3b2-3a3e-48d6-a977-12c7a8b0b9b7",
                "title": "Software Developer",
                "company": "Zadley India",
                "duration": "May 2023 - Now",
                'avatar': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHATIwrqZXCYAMXolUAiD9EmGpuGzxPsS5WQ&s',
                "description": ""
            },
        ],
        "education": [
            {
                "ID": "f8e9c8a7-7a7d-4f6e-9b8c-3f7a5c9d7b3e",
                "degree": "Bsc. Cs",
                'avatar': 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Logo_SCSC.jpg/800px-Logo_SCSC.jpg',
                "duration": "2021 - 2024",
                "institution": "Salesian College",
            },
        ],
        "skills": [
            { "ID": "a8c9e8b8-4d4a-4f7e-9b7e-1a7d8f9b7c9d", "skill": "Paytm" },
            { "ID": "b7e9c7a8-4d5a-4e8e-8c7d-2a7e9c7b8d9e", "skill": "Material Ui" },
            { "ID": "c7d9e8b8-4d6a-4f8e-9d7e-3a7e9d8b9c7e", "skill": "Linguistic" },
        ],
        "address": "Wow homes pg , Mamura , sector 66 , noida",
        "state": "Uttar Pradesh",
        "country": "India",
        "city": "noida",
        "zipCode": "201301",
        "avatar": "https://pbs.twimg.com/profile_images/1808949735778091008/q_BRRkEa_400x400.jpg",
        "resume": "https://drive.google.com/file/d/17d4ioJ9SPYo3-nE8zr-Ql37PSJ2lmObW/view?usp=drivesdk",
        "dateOfBirth": "2004-07-31"
    }
];

export const salutationList = [
    { value: "mr", label: "Mr." },
    { value: "mrs", label: "Mrs." },
    { value: "ms", label: "Ms." },
    { value: "dr", label: "Dr." },
    { value: "prof", label: "Prof." }
];


export const designationsList = [
    {
        "ID": "550e8400-e29b-41d4-a716-446655440000",
        "Name": "Editor-in-Chief",
        "description": "Oversees overall news strategy, quality, and editorial direction.",
        "active": true,
        "created_on": "2025-03-11T10:00:00Z"
    },
    {
        "ID": "6cd3556d-dc3b-4a1e-9b56-b1c1f7f9a098",
        "Name": "Managing Editor",
        "description": "Handles daily newsroom operations and manages the editorial team.",
        "active": true,
        "created_on": "2025-03-11T10:05:00Z"
    },
    {
        "ID": "7902a1e6-8d77-4c1c-94b4-92e34f65b5b1",
        "Name": "Senior Editor",
        "description": "Supervises and mentors journalists, ensuring editorial standards.",
        "active": true,
        "created_on": "2025-03-11T10:10:00Z"
    },
    {
        "ID": "af7837c8-3f41-4296-bd8b-d73508f9a4d9",
        "Name": "News Editor",
        "description": "Responsible for selecting, reviewing, and finalizing news content.",
        "active": true,
        "created_on": "2025-03-11T10:15:00Z"
    },
    {
        "ID": "d92a6b94-f7b7-44a5-8983-5bb3f8c9d72d",
        "Name": "Assistant Editor",
        "description": "Supports senior editors with content editing and newsroom management.",
        "active": true,
        "created_on": "2025-03-11T10:20:00Z"
    },
    {
        "ID": "e7c631b9-29d1-432f-a6b9-2c7c50568512",
        "Name": "Reporter",
        "description": "Gathers, researches, and writes news stories for various platforms.",
        "active": true,
        "created_on": "2025-03-11T10:25:00Z"
    },
    {
        "ID": "fbd8753c-542c-4b74-8a5e-8bba48e07a75",
        "Name": "Investigative Journalist",
        "description": "Conducts deep research and investigations to uncover critical issues.",
        "active": true,
        "created_on": "2025-03-11T10:30:00Z"
    },
    {
        "ID": "1a4f9f32-55f3-4ec8-b129-62d1e6219a9c",
        "Name": "Feature Writer",
        "description": "Writes in-depth articles and human-interest stories.",
        "active": true,
        "created_on": "2025-03-11T10:35:00Z"
    },
    {
        "ID": "2c9e7bb5-88c4-4a8a-91f7-3a6e2f6d539e",
        "Name": "Columnist",
        "description": "Provides expert opinions and commentary on specific topics.",
        "active": true,
        "created_on": "2025-03-11T10:40:00Z"
    },
    {
        "ID": "b2d8a245-d16d-4e6c-96cb-9b567ed0f527",
        "Name": "Photojournalist",
        "description": "Captures compelling images to complement news stories.",
        "active": true,
        "created_on": "2025-03-11T10:45:00Z"
    },
    {
        "ID": "34b3f6c8-4cd1-49c6-bd5f-5f2b6a9c8b27",
        "Name": "Video Journalist",
        "description": "Produces and edits video content for news coverage.",
        "active": true,
        "created_on": "2025-03-11T10:50:00Z"
    },
    {
        "ID": "43a6581a-dc97-42d4-b0c4-fb3b50872957",
        "Name": "Social Media Manager",
        "description": "Manages social media presence and audience engagement.",
        "active": true,
        "created_on": "2025-03-11T10:55:00Z"
    },
    {
        "ID": "7cdb0a17-2f73-4b6d-909f-740c7a91fc1e",
        "Name": "News Anchor",
        "description": "Presents news on television or digital platforms.",
        "active": true,
        "created_on": "2025-03-11T11:00:00Z"
    },
    {
        "ID": "ae15b6d7-9843-4fa7-a9f1-8b67e923c47a",
        "Name": "Copy Editor",
        "description": "Ensures accuracy, clarity, and grammatical correctness in news content.",
        "active": true,
        "created_on": "2025-03-11T11:05:00Z"
    },
    {
        "ID": "c543bfb2-5f87-4b8f-82f4-8e21329d97dc",
        "Name": "Graphics Designer",
        "description": "Creates visual elements and infographics for news stories.",
        "active": true,
        "created_on": "2025-03-11T11:10:00Z"
    },
    {
        "ID": "e6178e46-6d3b-4d8b-a8e7-0e8e3a9d64e1",
        "Name": "Web Developer",
        "description": "Maintains and enhances the news website and digital platforms.",
        "active": true,
        "created_on": "2025-03-11T11:15:00Z"
    },
    {
        "ID": "fa48603b-77a5-4913-a9d7-356b6fa31827",
        "Name": "Producer",
        "description": "Manages content production for digital, TV, or radio platforms.",
        "active": true,
        "created_on": "2025-03-11T11:20:00Z"
    }
]

export const departments = [
    {
        "ID": "dep-001",
        "Name": "Politics",
        "description": "Covers political news, government policies, and elections",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true
    },
    {
        "ID": "dep-002",
        "Name": "Sports",
        "description": "Covers national and international sports events and updates",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true
    },
    {
        "ID": "dep-003",
        "Name": "Technology",
        "description": "Focuses on latest tech trends, gadgets, and innovations",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true
    },
    {
        "ID": "dep-004",
        "Name": "Entertainment",
        "description": "Covers movies, music, celebrities, and pop culture news",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true
    },
    {
        "ID": "dep-005",
        "Name": "Business",
        "description": "Reports on financial markets, economy, and corporate news",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true
    },
    {
        "ID": "dep-006",
        "Name": "Health",
        "description": "Focuses on medical news, healthcare advancements, and fitness",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true
    },
    {
        "ID": "dep-007",
        "Name": "Science",
        "description": "Explores scientific discoveries, space exploration, and research",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true
    },
    {
        "ID": "dep-008",
        "Name": "World News",
        "description": "Covers major global events and international affairs",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true
    },
    {
        "ID": "dep-009",
        "Name": "Crime",
        "description": "Focuses on crime reports, investigations, and law enforcement updates",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true
    },
    {
        "ID": "dep-010",
        "Name": "Education",
        "description": "Covers academic news, exams, and policy updates in education",
        "CreatedAt": "2025-03-09T12:00:00Z",
        "UpdatedAt": "2025-03-09T12:00:00Z",
        "isActive": true
    }
];



export const licenses = [
    {
        "id": "all_rights_reserved",
        "name": "All Rights Reserved",
        "description": "Full copyright protection. No reuse without explicit permission."
    },
    {
        "id": "cc_by",
        "name": "Creative Commons - Attribution (CC BY)",
        "description": "Allows reuse with credit to the author."
    },
    {
        "id": "point_of_india_license",
        "name": "Point of India License (Custom)",
        "description": "Custom licensing terms defined by Point of India for content usage."
    }
]







export const loginFormat = {
    user: {
        token: {
            accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHByb3ZlIjp7ImlzRmluaXNoUHJvZHVjdEFwcHJvdmVyIjp0cnVlfSwidXNlcmlkIjoiRTdCRUQzMTItNDg5My00MjcwLUFDMDEtQzQyOUQ3NzkwQ0YyIiwidXNlcl9uYW1lIjoiTUQgQWthc2giLCJ1c2VyX2dyb3VwIjoiMi4yIEFwcHMgVGVhbSIsInVzZXJfY29tcGFueSI6IlpBRExFWSBJTkRJQSBQUklWQVRFIExJTUlURUQiLCJVc2VyR3JvdXBJRCI6IkMwOTZGRTI3LUI0REEtNEM3RS04NjM5LTNDOEZGNkQ3RDZBNCIsImNvbXBhbnlpZCI6IjdBNTU4ODNFLTBCQjEtNEYxNi1BREJCLUY4NjBBNDQ2RUU0NyIsImRibmFtZSI6IlpBRExFWSIsInNlc3Npb25pZCI6ImYyZTBlZGI2LTA1YjUtNDQwNS05YzgzLTIzZmExODJiMzc0NyIsImFwcHR5cGUiOjMwLCJ2YWxpZGZyb20iOjE3NDE5Njk5NjUuODMyLCJpc1JlZnJlc2giOmZhbHNlLCJFbXBDVklEIjoiODE1MEU2QjAtQ0YyMS00M0RGLTlEN0QtNDExNjFCMUZFM0E1IiwiY29tcmlnaHRJZHMiOiI3QTU1ODgzRS0wQkIxLTRGMTYtQURCQi1GODYwQTQ0NkVFNDciLCJVcmwiOiJodHRwczovL2Rta3V3OWRxZjc0ejguY2xvdWRmcm9udC5uZXQiLCJTR19Db25maWciOiJ7XCJJc1JldGFpbGVyQXBwbGljYWJsZVwiOjEsXCJXaGF0c2FwcE1hbmFnZW1lbnRcIjpcIkU0OURBQTFDLUY3Q0MtNDI1RC1BQzRCLUI5MDI5RDE3RDI0NVwifSIsInVzZXJuYW1lIjoiYWthc2giLCJkZWZhdWx0T2JqIjp7ImZjYUFwaUtleSI6Ijc5RTEwQUVGLTg1NkEtNEIwMi1BQkZDLTVBMDQxNjNEMjVGQiIsImZjYUFwaVVybCI6Imh0dHBzOi8vZmNhYXBpLnNpbmdsYWdyb3Vwcy5jb20iLCJGSU5fU1RBUlRfREFURSI6IjIwMjMtMDQtMDFUMDA6MDA6MDAuMDAwWiIsIkZJTl9FTkRfREFURSI6IjIwMjUtMDMtMzFUMDA6MDA6MDAuMDAwWiIsIkJyYW5jaCI6ImYzMGNmYjk3LTg1YmEtNGM1Ny04YTVkLTU4ZDg5ZTg5NDkwZCIsIldhcmVob3VzZSI6IjZkMzhiNjJkLWFkM2MtNDUxMi05MGE5LTQxZjZlOTRkODU0ZSIsIkRpdmlzaW9uIjoiNzcyOTY5YjItODAwZS00OWZhLTgzNjAtMjc5NGEzYmU3OTFlIn0sImp0aSI6ImVhNTZhZTg1LWZiMTYtNDY4MC05ZjkzLTU3Y2U0Zjc5MzgwMiIsImlhdCI6MTc0MTk2OTk2NSwiZXhwIjoxNzQxOTczNTY1fQ.pwqHQsovfd1n-jJ6j6u5QOxchqlb4H9mpH9ZCDz17kA',
            refreshToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHByb3ZlIjp7ImlzRmluaXNoUHJvZHVjdEFwcHJvdmVyIjp0cnVlfSwidXNlcmlkIjoiRTdCRUQzMTItNDg5My00MjcwLUFDMDEtQzQyOUQ3NzkwQ0YyIiwidXNlcl9uYW1lIjoiTUQgQWthc2giLCJ1c2VyX2dyb3VwIjoiMi4yIEFwcHMgVGVhbSIsInVzZXJfY29tcGFueSI6IlpBRExFWSBJTkRJQSBQUklWQVRFIExJTUlURUQiLCJjb21wYW55aWQiOiI3QTU1ODgzRS0wQkIxLTRGMTYtQURCQi1GODYwQTQ0NkVFNDciLCJkYm5hbWUiOiJaQURMRVkiLCJVc2VyR3JvdXBJRCI6IkMwOTZGRTI3LUI0REEtNEM3RS04NjM5LTNDOEZGNkQ3RDZBNCIsInNlc3Npb25pZCI6ImYyZTBlZGI2LTA1YjUtNDQwNS05YzgzLTIzZmExODJiMzc0NyIsImFwcHR5cGUiOjMwLCJFbXBDVklEIjoiODE1MEU2QjAtQ0YyMS00M0RGLTlEN0QtNDExNjFCMUZFM0E1IiwidmFsaWRmcm9tIjoxNzQxOTY5OTY1LjgzMiwiaXNSZWZyZXNoIjp0cnVlLCJjb21yaWdodElkcyI6IjdBNTU4ODNFLTBCQjEtNEYxNi1BREJCLUY4NjBBNDQ2RUU0NyIsIlVybCI6Imh0dHBzOi8vZG1rdXc5ZHFmNzR6OC5jbG91ZGZyb250Lm5ldCIsIlNHX0NvbmZpZyI6IntcIklzUmV0YWlsZXJBcHBsaWNhYmxlXCI6MSxcIldoYXRzYXBwTWFuYWdlbWVudFwiOlwiRTQ5REFBMUMtRjdDQy00MjVELUFDNEItQjkwMjlEMTdEMjQ1XCJ9IiwidXNlcm5hbWUiOiJha2FzaCIsImRlZmF1bHRPYmoiOnsiZmNhQXBpS2V5IjoiNzlFMTBBRUYtODU2QS00QjAyLUFCRkMtNUEwNDE2M0QyNUZCIiwiZmNhQXBpVXJsIjoiaHR0cHM6Ly9mY2FhcGkuc2luZ2xhZ3JvdXBzLmNvbSIsIkZJTl9TVEFSVF9EQVRFIjoiMjAyMy0wNC0wMVQwMDowMDowMC4wMDBaIiwiRklOX0VORF9EQVRFIjoiMjAyNS0wMy0zMVQwMDowMDowMC4wMDBaIiwiQnJhbmNoIjoiZjMwY2ZiOTctODViYS00YzU3LThhNWQtNThkODllODk0OTBkIiwiV2FyZWhvdXNlIjoiNmQzOGI2MmQtYWQzYy00NTEyLTkwYTktNDFmNmU5NGQ4NTRlIiwiRGl2aXNpb24iOiI3NzI5NjliMi04MDBlLTQ5ZmEtODM2MC0yNzk0YTNiZTc5MWUifSwianRpIjoiOTgzZGU2MjEtZTJkYS00YjJkLWEzMGItYmI0YTdiMWJhNzhkIiwiaWF0IjoxNzQxOTY5OTY1LCJleHAiOjE3NDE5OTg3NjV9.PVI8pckYXHVfuSrfqG25OvjWkVsixZsf87ZMa2cPdJg'
        },
        userInfo: {
            username: 'Akash',
            cellno: '7029793127',
            email: '',
            name: 'MD Akash',
            usergroup: '2.2 Apps Team',
        },
        sideMenu: [
            {
                ID: 'f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d',
                link: '/administration',
                name: 'Administration',
                component: 'Administration',
                type: 1,
                ChildList: [
                    {
                        ID: 'a1b2c3d4-e5f6-7a8b-9c0d-1e2f3b4c5d6e',
                        Name: 'Module Management',
                        UnderID: 'f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d',
                        Sequence: 1,
                        link: '/module-management',
                        linkVal: 'ModuleManagement',
                        ChildList: [
                            {
                                ID: 'b1c2d3e4-f5a6-7b8c-9d0e-1f2b3c4d5e6f',
                                Name: 'Create Module',
                                UnderID: 'a1b2c3d4-e5f6-7a8b-9c0d-1e2f3b4c5d6e',
                                Sequence: 1,
                                link: '/create',
                                linkVal: 'CreateModule',
                                inactive: 0,
                                type: 3
                            },
                            {
                                ID: 'c1d2e3f4-a5b6-7c8d-9e0f-1b2c3d4e5f6a',
                                Name: 'Edit Module',
                                UnderID: 'a1b2c3d4-e5f6-7a8b-9c0d-1e2f3b4c5d6e',
                                Sequence: 2,
                                link: '/edit',
                                linkVal: 'EditModule',
                                inactive: 0,
                                type: 3
                            }
                        ],
                        inactive: 0,
                        type: 2
                    },
                    {
                        ID: 'd1e2f3a4-b5c6-7d8e-9f0a-1c2d3e4f5b6a',
                        Name: 'Group Management',
                        UnderID: 'f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d',
                        Sequence: 2,
                        link: '/group-management',
                        linkVal: 'GroupManagement',
                        ChildList: [
                            {
                                ID: 'e1f2a3b4-c5d6-7e8f-9a0b-1d2c3e4f5a6b',
                                Name: 'Create Group',
                                UnderID: 'd1e2f3a4-b5c6-7d8e-9f0a-1c2d3e4f5b6a',
                                Sequence: 1,
                                link: '/create',
                                linkVal: 'CreateGroup',
                                inactive: 0,
                                type: 3
                            },
                            {
                                ID: 'f1a2b3c4-d5e6-7f8a-9b0c-1e2d3c4f5a6b',
                                Name: 'Edit Group',
                                UnderID: 'd1e2f3a4-b5c6-7d8e-9f0a-1c2d3e4f5b6a',
                                Sequence: 2,
                                link: '/edit',
                                linkVal: 'EditGroup',
                                inactive: 0,
                                type: 3
                            }
                        ],
                        inactive: 0,
                        type: 2
                    },
                    {
                        ID: 'a2b3c4d5-e6f7-8a9b-0c1d-2e3f4b5c6d7a',
                        Name: 'User Management',
                        UnderID: 'f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d',
                        Sequence: 3,
                        link: '/user-management',
                        linkVal: 'UserManagement',
                        ChildList: [
                            {
                                ID: 'b2c3d4e5-f6a7-8b9c-0d1e-2f3b4c5d6e7f',
                                Name: 'Create User',
                                UnderID: 'a2b3c4d5-e6f7-8a9b-0c1d-2e3f4b5c6d7a',
                                Sequence: 1,
                                link: '/create',
                                linkVal: 'CreateUser',
                                inactive: 0,
                                type: 3
                            },
                            {
                                ID: 'c2d3e4f5-a6b7-8c9d-0e1f-2b3c4d5e6f7a',
                                Name: 'Edit User',
                                UnderID: 'a2b3c4d5-e6f7-8a9b-0c1d-2e3f4b5c6d7a',
                                Sequence: 2,
                                link: '/edit',
                                linkVal: 'EditUser',
                                inactive: 0,
                                type: 3
                            }
                        ],
                        inactive: 0,
                        type: 2
                    },
                    {
                        ID: 'a2b3c4d5-e6f7-8a9b-0c1d-2e3f4b5c6d7a',
                        Name: 'Permission Management',
                        UnderID: 'f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d',
                        Sequence: 3,
                        link: '/permission-management',
                        linkVal: 'PermissionManagement',
                        inactive: 0,
                        type: 2
                    }
                ]
            },
            {
                ID: 'j4k5l6m7-n8o9-40p1-q2r3-s4t5u6v7w8x9',
                link: '/advertisement-management',
                name: 'Advertisement Management',
                component: 'AdvertisementManagement',
                type: 1,
                ChildList: [
                    {
                        ID: 'k5l6m7n8-o9p0-41q2-r3s4-t5u6v7w8x9y0',
                        Name: 'Create Advertisement',
                        UnderID: 'j4k5l6m7-n8o9-40p1-q2r3-s4t5u6v7w8x9',
                        Sequence: 1,
                        link: '/create-advertisement',
                        linkVal: 'CreateAdvertisement',
                        inactive: 0,
                        type: 3
                    },
                    {
                        ID: 'l6m7n8o9-p0q1-42r3-s4t5-u6v7w8x9y0z1',
                        Name: 'Manage Advertisement',
                        UnderID: 'j4k5l6m7-n8o9-40p1-q2r3-s4t5u6v7w8x9',
                        Sequence: 2,
                        link: '/manage-advertisement',
                        linkVal: 'ManageAdvertisement',
                        inactive: 0,
                        type: 3
                    }
                ]
            },
            {
                ID: 'd7e8f9a1-b2c3-44d5-8e9f-0a1b2c3d4e5f',
                link: '/user-management',
                name: 'User Management',
                component: 'UserManagement',
                type: 1,
                ChildList: [
                    {
                        ID: 'e6f7a8b9-c0d1-42e3-f4b5c6d7e8f9',
                        Name: 'Journalist Management',
                        UnderID: 'd7e8f9a1-b2c3-44d5-8e9f-0a1b2c3d4e5f',
                        Sequence: 1,
                        link: '/journalist-management',
                        linkVal: 'JournalistManagement',
                        inactive: 0,
                        type: 2,
                        ChildList: [
                            {
                                ID: 'f5a6b7c8-d9e0-41f2-b3c4d5e6f7a8',
                                Name: 'Add Journalist',
                                UnderID: 'e6f7a8b9-c0d1-42e3-f4b5c6d7e8f9',
                                Sequence: 1,
                                link: '/add-journalist',
                                linkVal: 'AddJournalist',
                                inactive: 0,
                                type: 3
                            },
                            {
                                ID: 'g1h2i3j4-k5l6-47m8-n9o0-p1q2r3s4t5u6',
                                Name: 'Edit Journalist',
                                UnderID: 'e6f7a8b9-c0d1-42e3-f4b5c6d7e8f9',
                                Sequence: 2,
                                link: '/edit-journalist',
                                linkVal: 'EditJournalist',
                                inactive: 0,
                                type: 3
                            }
                        ]
                    },
                    {
                        ID: 'h2i3j4k5-l6m7-49n0-o1p2-q3r4s5t6u7v8',
                        Name: 'Reader Management',
                        UnderID: 'd7e8f9a1-b2c3-44d5-8e9f-0a1b2c3d4e5f',
                        Sequence: 2,
                        link: '/reader-management',
                        linkVal: 'ReaderManagement',
                        inactive: 0,
                        type: 2,
                        ChildList: [
                            {
                                ID: 'i3j4k5l6-m7n8-41o9-p0q1-r2s3t4u5v6w7',
                                Name: 'Manage Subscriptions',
                                UnderID: 'h2i3j4k5-l6m7-49n0-o1p2-q3r4s5t6u7v8',
                                Sequence: 1,
                                link: '/manage-subscriptions',
                                linkVal: 'ManageSubscriptions',
                                inactive: 0,
                                type: 3
                            }
                        ]
                    }
                ]
            },
            {
                ID: 'b3f2e5a4-d1c6-47f9-a9d2-3f4b5c6d7e8f',
                link: '/content-management',
                name: 'Content Management',
                component: 'ContentManagement',
                type: 1,
                ChildList: [
                    {
                        ID: 'a6d8e9f1-b2c3-45d7-8e9f-0a1b2c3d4e5f',
                        Name: 'Create Article',
                        UnderID: 'b3f2e5a4-d1c6-47f9-a9d2-3f4b5c6d7e8f',
                        Sequence: 1,
                        link: '/create-article',
                        linkVal: 'CreateArticle',
                        inactive: 0,
                        type: 3
                    },
                    {
                        ID: 'c4d5e6f7-a8b9-40c1-d2e3f4b5c6d7',
                        Name: 'Edit Article',
                        UnderID: 'b3f2e5a4-d1c6-47f9-a9d2-3f4b5c6d7e8f',
                        Sequence: 2,
                        link: '/edit-article',
                        linkVal: 'EditArticle',
                        inactive: 0,
                        type: 3
                    }
                ]
            }
        ],
        routes: [
            {
                link: '/administration/module-management/create',
                name: 'Create Module',
                WebLink: '',
                component: 'CreateModule',
                AsanaTaskLink: '',
                moduleId: 'b1c2d3e4-f5a6-7b8c-9d0e-1f2b3c4d5e6f'
            },
            {
                link: '/administration/module-management/edit',
                name: 'Edit Module',
                WebLink: '',
                component: 'EditModule',
                AsanaTaskLink: '',
                moduleId: 'c1d2e3f4-a5b6-7c8d-9e0f-1b2c3d4e5f6a'
            },
            {
                link: '/administration/module-management',
                name: 'Module Management',
                WebLink: '',
                component: 'ModuleManagement',
                AsanaTaskLink: '',
                moduleId: 'a1b2c3d4-e5f6-7a8b-9c0d-1e2f3b4c5d6e'
            },
            {
                link: '/administration/group-management/create',
                name: 'Create Group',
                WebLink: '',
                component: 'CreateGroup',
                AsanaTaskLink: '',
                moduleId: 'e1f2a3b4-c5d6-7e8f-9a0b-1d2c3e4f5a6b'
            },
            {
                link: '/administration/group-management/edit',
                name: 'Edit Group',
                WebLink: '',
                component: 'EditGroup',
                AsanaTaskLink: '',
                moduleId: 'f1a2b3c4-d5e6-7f8a-9b0c-1e2d3c4f5a6b'
            },
            {
                link: '/administration/group-management',
                name: 'Group Management',
                WebLink: '',
                component: 'GroupManagement',
                AsanaTaskLink: '',
                moduleId: 'd1e2f3a4-b5c6-7d8e-9f0a-1c2d3e4f5b6a'
            },
            {
                link: '/administration/user-management/create',
                name: 'Create User',
                WebLink: '',
                component: 'CreateUser',
                AsanaTaskLink: '',
                moduleId: 'b2c3d4e5-f6a7-8b9c-0d1e-2f3b4c5d6e7f'
            },
            {
                link: '/administration/user-management/edit',
                name: 'Edit User',
                WebLink: '',
                component: 'EditUser',
                AsanaTaskLink: '',
                moduleId: 'c2d3e4f5-a6b7-8c9d-0e1f-2b3c4d5e6f7a'
            },
            {
                link: '/administration/user-management',
                name: 'User Management',
                WebLink: '',
                component: 'UserManagement',
                AsanaTaskLink: '',
                moduleId: 'a2b3c4d5-e6f7-8a9b-0c1d-2e3f4b5c6d7a'
            },
            {
                link: '/administration/permission-management',
                name: 'Permission Management',
                WebLink: '',
                component: 'PermissionManagement',
                AsanaTaskLink: '',
                moduleId: 'a2b3c4d5-e6f7-8a9b-0c1d-2e3f4b5c6d7a'
            },
            {
                link: '/administration',
                WebLink: '',
                component: 'Administration',
                AsanaTaskLink: '',
                moduleId: 'f1a2b3c4-d5e6-7f8a-9b0c-1d2e3f4b5c6d'
            },
            {
                link: '/advertisement-management/create-advertisement',
                name: 'Create Advertisement',
                WebLink: '',
                component: 'CreateAdvertisement',
                AsanaTaskLink: '',
                moduleId: 'k5l6m7n8-o9p0-41q2-r3s4-t5u6v7w8x9y0'
            },
            {
                link: '/advertisement-management/manage-advertisement',
                name: 'Manage Advertisement',
                WebLink: '',
                component: 'ManageAdvertisement',
                AsanaTaskLink: '',
                moduleId: 'l6m7n8o9-p0q1-42r3-s4t5-u6v7w8x9y0z1'
            },
            {
                link: '/advertisement-management',
                WebLink: '',
                component: 'AdvertisementManagement',
                AsanaTaskLink: '',
                moduleId: 'j4k5l6m7-n8o9-40p1-q2r3-s4t5u6v7w8x9'
            },
            {
                link: '/user-management/journalist-management/add-journalist',
                name: 'Add Journalist',
                WebLink: '',
                component: 'AddJournalist',
                AsanaTaskLink: '',
                moduleId: 'f5a6b7c8-d9e0-41f2-b3c4d5e6f7a8'
            },
            {
                link: '/user-management/journalist-management/edit-journalist',
                name: 'Edit Journalist',
                WebLink: '',
                component: 'EditJournalist',
                AsanaTaskLink: '',
                moduleId: 'g1h2i3j4-k5l6-47m8-n9o0-p1q2r3s4t5u6'
            },
            {
                link: '/user-management/journalist-management',
                name: 'Journalist Management',
                WebLink: '',
                component: 'JournalistManagement',
                AsanaTaskLink: '',
                moduleId: 'e6f7a8b9-c0d1-42e3-f4b5c6d7e8f9'
            },
            {
                link: '/user-management/reader-management/manage-subscriptions',
                name: 'Manage Subscriptions',
                WebLink: '',
                component: 'ManageSubscriptions',
                AsanaTaskLink: '',
                moduleId: 'i3j4k5l6-m7n8-41o9-p0q1-r2s3t4u5v6w7'
            },
            {
                link: '/user-management/reader-management',
                name: 'Reader Management',
                WebLink: '',
                component: 'ReaderManagement',
                AsanaTaskLink: '',
                moduleId: 'h2i3j4k5-l6m7-49n0-o1p2-q3r4s5t6u7v8'
            },
            {
                link: '/user-management',
                WebLink: '',
                component: 'UserManagement',
                AsanaTaskLink: '',
                moduleId: 'd7e8f9a1-b2c3-44d5-8e9f-0a1b2c3d4e5f'
            },
            {
                link: '/content-management/create-article',
                name: 'Create Article',
                WebLink: '',
                component: 'CreateArticle',
                AsanaTaskLink: '',
                moduleId: 'a6d8e9f1-b2c3-45d7-8e9f-0a1b2c3d4e5f'
            },
            {
                link: '/content-management/edit-article',
                name: 'Edit Article',
                WebLink: '',
                component: 'EditArticle',
                AsanaTaskLink: '',
                moduleId: 'c4d5e6f7-a8b9-40c1-d2e3f4b5c6d7'
            },
            {
                link: '/content-management',
                WebLink: '',
                component: 'ContentManagement',
                AsanaTaskLink: '',
                moduleId: 'b3f2e5a4-d1c6-47f9-a9d2-3f4b5c6d7e8f'
            }
        ]
    }
}


export const loginResults = {
    "status": 1,
    "data": {
        "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHByb3ZlIjp7ImlzRmluaXNoUHJvZHVjdEFwcHJvdmVyIjp0cnVlfSwidXNlcmlkIjoiRTdCRUQzMTItNDg5My00MjcwLUFDMDEtQzQyOUQ3NzkwQ0YyIiwidXNlcl9uYW1lIjoiTUQgQWthc2giLCJ1c2VyX2dyb3VwIjoiMi4yIEFwcHMgVGVhbSIsInVzZXJfY29tcGFueSI6IlpBRExFWSBJTkRJQSBQUklWQVRFIExJTUlURUQiLCJVc2VyR3JvdXBJRCI6IkMwOTZGRTI3LUI0REEtNEM3RS04NjM5LTNDOEZGNkQ3RDZBNCIsImNvbXBhbnlpZCI6IjdBNTU4ODNFLTBCQjEtNEYxNi1BREJCLUY4NjBBNDQ2RUU0NyIsImRibmFtZSI6IlpBRExFWSIsInNlc3Npb25pZCI6ImJjMmNiNDU3LWI3NGYtNGM0ZC05NjNjLWU2MmE2ZGJmYmFkNyIsImFwcHR5cGUiOjMwLCJ2YWxpZGZyb20iOjE3NDIwNjI5NTYuODI3LCJpc1JlZnJlc2giOmZhbHNlLCJFbXBDVklEIjoiODE1MEU2QjAtQ0YyMS00M0RGLTlEN0QtNDExNjFCMUZFM0E1IiwiY29tcmlnaHRJZHMiOiI3QTU1ODgzRS0wQkIxLTRGMTYtQURCQi1GODYwQTQ0NkVFNDciLCJVcmwiOiJodHRwczovL2Rta3V3OWRxZjc0ejguY2xvdWRmcm9udC5uZXQiLCJTR19Db25maWciOiJ7XCJJc1JldGFpbGVyQXBwbGljYWJsZVwiOjEsXCJXaGF0c2FwcE1hbmFnZW1lbnRcIjpcIkU0OURBQTFDLUY3Q0MtNDI1RC1BQzRCLUI5MDI5RDE3RDI0NVwifSIsInVzZXJuYW1lIjoiYWthc2giLCJkZWZhdWx0T2JqIjp7ImZjYUFwaUtleSI6Ijc5RTEwQUVGLTg1NkEtNEIwMi1BQkZDLTVBMDQxNjNEMjVGQiIsImZjYUFwaVVybCI6Imh0dHBzOi8vZmNhYXBpLnNpbmdsYWdyb3Vwcy5jb20iLCJGSU5fU1RBUlRfREFURSI6IjIwMjMtMDQtMDFUMDA6MDA6MDAuMDAwWiIsIkZJTl9FTkRfREFURSI6IjIwMjUtMDMtMzFUMDA6MDA6MDAuMDAwWiIsIkJyYW5jaCI6ImYzMGNmYjk3LTg1YmEtNGM1Ny04YTVkLTU4ZDg5ZTg5NDkwZCIsIldhcmVob3VzZSI6IjZkMzhiNjJkLWFkM2MtNDUxMi05MGE5LTQxZjZlOTRkODU0ZSIsIkRpdmlzaW9uIjoiNzcyOTY5YjItODAwZS00OWZhLTgzNjAtMjc5NGEzYmU3OTFlIn0sImp0aSI6IjFkM2NkNTMwLTA2ZjUtNGNmMi05YjY5LWIzZmRhN2RlMWM0MyIsImlhdCI6MTc0MjA2Mjk1NiwiZXhwIjoxNzQyMDY2NTU2fQ.7z86tkCpT16OkyOIHlJP1qydzHRMeO2gy8d4bsTdToo",
        "refresh_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHByb3ZlIjp7ImlzRmluaXNoUHJvZHVjdEFwcHJvdmVyIjp0cnVlfSwidXNlcmlkIjoiRTdCRUQzMTItNDg5My00MjcwLUFDMDEtQzQyOUQ3NzkwQ0YyIiwidXNlcl9uYW1lIjoiTUQgQWthc2giLCJ1c2VyX2dyb3VwIjoiMi4yIEFwcHMgVGVhbSIsInVzZXJfY29tcGFueSI6IlpBRExFWSBJTkRJQSBQUklWQVRFIExJTUlURUQiLCJjb21wYW55aWQiOiI3QTU1ODgzRS0wQkIxLTRGMTYtQURCQi1GODYwQTQ0NkVFNDciLCJkYm5hbWUiOiJaQURMRVkiLCJVc2VyR3JvdXBJRCI6IkMwOTZGRTI3LUI0REEtNEM3RS04NjM5LTNDOEZGNkQ3RDZBNCIsInNlc3Npb25pZCI6ImJjMmNiNDU3LWI3NGYtNGM0ZC05NjNjLWU2MmE2ZGJmYmFkNyIsImFwcHR5cGUiOjMwLCJFbXBDVklEIjoiODE1MEU2QjAtQ0YyMS00M0RGLTlEN0QtNDExNjFCMUZFM0E1IiwidmFsaWRmcm9tIjoxNzQyMDYyOTU2LjgyNywiaXNSZWZyZXNoIjp0cnVlLCJjb21yaWdodElkcyI6IjdBNTU4ODNFLTBCQjEtNEYxNi1BREJCLUY4NjBBNDQ2RUU0NyIsIlVybCI6Imh0dHBzOi8vZG1rdXc5ZHFmNzR6OC5jbG91ZGZyb250Lm5ldCIsIlNHX0NvbmZpZyI6IntcIklzUmV0YWlsZXJBcHBsaWNhYmxlXCI6MSxcIldoYXRzYXBwTWFuYWdlbWVudFwiOlwiRTQ5REFBMUMtRjdDQy00MjVELUFDNEItQjkwMjlEMTdEMjQ1XCJ9IiwidXNlcm5hbWUiOiJha2FzaCIsImRlZmF1bHRPYmoiOnsiZmNhQXBpS2V5IjoiNzlFMTBBRUYtODU2QS00QjAyLUFCRkMtNUEwNDE2M0QyNUZCIiwiZmNhQXBpVXJsIjoiaHR0cHM6Ly9mY2FhcGkuc2luZ2xhZ3JvdXBzLmNvbSIsIkZJTl9TVEFSVF9EQVRFIjoiMjAyMy0wNC0wMVQwMDowMDowMC4wMDBaIiwiRklOX0VORF9EQVRFIjoiMjAyNS0wMy0zMVQwMDowMDowMC4wMDBaIiwiQnJhbmNoIjoiZjMwY2ZiOTctODViYS00YzU3LThhNWQtNThkODllODk0OTBkIiwiV2FyZWhvdXNlIjoiNmQzOGI2MmQtYWQzYy00NTEyLTkwYTktNDFmNmU5NGQ4NTRlIiwiRGl2aXNpb24iOiI3NzI5NjliMi04MDBlLTQ5ZmEtODM2MC0yNzk0YTNiZTc5MWUifSwianRpIjoiMWZlNDZhNzUtZjE5OS00ZjQ4LWI0YzQtZTQyYjY1MGZhOGRiIiwiaWF0IjoxNzQyMDYyOTU2LCJleHAiOjE3NDIwOTE3NTZ9.WBZIMhhsXIfaVOc9F8z7M2Su8YYTbge1ldRj0TvJ1DU",
        "userdata": {
            "username": "Akash",
            "UserGroupID": "C096FE27-B4DA-4C7E-8639-3C8FF6D7D6A4",
            "cellno": "7029793127",
            "email": "mdakash@gmail.com",
            "name": "MD Akash",
            "usergroup": "2.2 Apps Team",
            "userimg": "https://links.singlagroups.com/db-image/user/ZADLEY/E7BED312-4893-4270-AC01-C429D7790CF2/user_1742062956910.jpg",
            "userRights": {
                "permissionArr": []
            }
        }
    },
    "msg": "Login Successfully, Welcome MD Akash ",
}