import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Wrapper from "../Architecture/Wrapper";

function Dashboard(props) {
	const { search } = useLocation();
	const router = useHistory();
	const searchParams = new URLSearchParams(search);
	const sidebarParam = searchParams.get("sidebar");
	const sidebar = sidebarParam !== null ? sidebarParam === 'true' : true;
	const userdata = useSelector((state) => state.user);
	if (!userdata.token.accessToken) {
		router.push('/login');
	}
	return (
		<>
			{
				sidebar ?
					<Wrapper>
						{props.children}
					</Wrapper> : props.children
			}
		</>
	);
}

export default Dashboard;
