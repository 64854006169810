import React from 'react';

const TaskEvents = () => {
    // Sample data for events and tasks
    const data = [
        {
            type: "event",
            date: "2023-11-15",
            title: "Politics Group Meeting",
            description: "Discuss the upcoming election strategy.",
            time: "03:00 PM",
            link: "https://meet.google.com/xyz",
            attendees: 15,
            action: "Join Meeting",
            actionIcon: "🔗",
        },
        {
            type: "task",
            date: "2023-11-20",
            title: "Complete Article on Vadodara Incident",
            description: "Check the references and complete the article.",
            assignedBy: "Admin",
            status: "Pending",
            action: "Start Task",
            actionIcon: "✔",
        },
        {
            type: "event",
            date: "2023-11-22",
            title: "Music Concert Planning",
            description: "Finalize the schedule and guest list.",
            time: "04:00 PM",
            link: "https://meet.google.com/abc",
            attendees: 10,
            action: "Reschedule",
            actionIcon: "📅",
        },
        {
            type: "task",
            date: "2023-11-23",
            title: "Review Tech Conference Keynote",
            description: "Prepare the keynote speech and slides.",
            assignedBy: "Admin",
            status: "In Progress",
            action: "Mark as Done",
            actionIcon: "✔",
        },
    ];

    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0];

    // Sort the data by date (ascending order)
    const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));

    return (
        <div className='border h-[60vh] w-[30%] bg-gray-50 rounded-lg px-4 py-3'>
            <h3 className='text-lg text-gray-700 mb-1'>Task & Events</h3>
            <div className="grid grid-cols-1 gap-4 overflow-y-auto h-[53vh]">
                {sortedData.map((item, index) => {
                    const itemDate = new Date(item.date).toISOString().split('T')[0];
                    const isToday = itemDate === today;

                    // Check if the date is within the last 7 days
                    const itemDateTime = new Date(item.date).getTime();
                    const sevenDaysAgo = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
                    const isLast7Days = itemDateTime >= sevenDaysAgo;

                    return (
                        <div key={index} className="bg-white border rounded-lg shadow-sm">
                            <div className="flex justify-start items-start">
                                {/* Date Box */}
                                <div className={`${isToday ? 'bg-purple-700' : 'bg-gray-800'} rounded-br-lg py-2 px-3 -space-y-1 text-white`}>
                                    <h4 className="text-lg">{new Date(item.date).getDate()}</h4>
                                    {isLast7Days ? (
                                        <p className='text-xs font-extralight'>
                                            {new Date(item.date).toLocaleString('default', { weekday: 'short' })}
                                        </p>
                                    ) : (
                                        <p className='text-base font-extralight'>
                                            {new Date(item.date).toLocaleString('default', { month: 'short' })}
                                        </p>
                                    )}
                                </div>
                                <div className="p-2">
                                    {item.type === "task" && (
                                        <div className="flex items-center text-xs whitespace-nowrap text-gray-600">
                                            <p>Assigned by: {item.assignedBy}</p>
                                        </div>
                                    )}
                                    <div className="flex items-center text-xs text-gray-600">
                                        <p className="text-lg font-medium text-gray-700">{item.title}</p>
                                    </div>
                                    {item.type === "event" && (
                                        <div className="flex items-center text-xs whitespace-nowrap text-gray-600">
                                            <p>{item.time}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="px-2 py-3">
                                <p className="text-xs text-gray-600 mt-2">{item.description}</p>
                                <div className="flex justify-between items-center mt-10">
                                    {item.type === "event" && (
                                        <span className="text-sm text-gray-500">{item.attendees} attendees</span>
                                    )}
                                    {item.type === "task" && (
                                        <span className="text-sm text-gray-500">Status: {item.status}</span>
                                    )}
                                    <button className="text-sm text-purple-600 hover:text-purple-800 flex items-center">
                                        {item.action} <span className="ml-1">{item.actionIcon}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TaskEvents;