import React from "react";
import SidebarMenu from "./SidebarMenu";
import { useSelector } from "react-redux";
import IsLoadingHOC from "../../components/common/Loader/Loader";
import { MdOutlineClose } from "react-icons/md";
import pointLogo from "../../assets/img/the-point-logo.png"
function Sidebar({ setShowSidebar }) {
	const user = useSelector((state) => state.user);
	const routes = user.sideMenu;
	return (
		<div className=" text-gray-800 border-r bg-white pt-7 sm:sticky fixed z-[999999] top-0 sm:z-[9999] md:w-80 sm:w-96 w-[70%] h-screen">
			<div className="w-full p-3 flex items-center justify-between mb-4">
				<div className="flex items-center gap-3">
					<img src={pointLogo} className="h-7 rounded-md" alt="point-logo" />
					<h4 className="text-xl font-medium">
						Point Of India
					</h4>
				</div>
				<span className="cursor-pointer" onClick={() => setShowSidebar(state => !state)}>
					<MdOutlineClose className="text-lg" />
				</span>
			</div>
			<SidebarMenu menulist={routes} />
		</div>
	);
}

export default React.memo(IsLoadingHOC(Sidebar, "Please Wait....."));
