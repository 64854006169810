import React from 'react';

const AuthInput = ({ label, required, name, ...rest }) => {
    return (
        <div className={`w-full relative rounded-md`}>
            {label && (
                <div className='flex items-center'>
                    <label
                        htmlFor={name}
                        className="text-sm font-ligh text-gray-600 capitalize"
                    >
                        {label}
                    </label>
                    {
                        required &&
                        <span className='text-red-500'>*</span>
                    }
                </div>
            )}
            <input
                className={`mt-1 text-xs block border border-gray-300 w-full px-3 py-[0.6rem] focus:outline-none`}
                id={name}
                name={name}

                {...rest}
            />
        </div>
    );
};

export default AuthInput;
