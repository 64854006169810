import React from 'react'
import Button from './Button'

const Heading = ({ text, headingclass='uppercase', buttonClass, isButton, handleSubmit, loading, buttonText, isBPM = true }) => {
    return (
        <din className={`flex items-center justify-between ${isBPM ? 'border-b pb-2 mb-6' : ''} `}>
            <h4 className={`${headingclass}`}>{text}</h4>
            {
                isButton ?
                    <Button text={buttonText} loading={loading} handleClick={handleSubmit} className={buttonClass} />
                    : ''
            }
        </din>
    )
}

export default Heading