import React, { useState } from 'react'
import Sidebar from './Sidebar/Sidebar'
import Header from './Header/Header';

const Wrapper = ({ children }) => {
    const [showSidebar, setShowSidebar] = useState(false);
    return (
        <div className='flex'>
            {
                showSidebar ?
                    <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    : ''
            }
            <div className={`${showSidebar ? 'lg:w-[80%] bg-gray-100 md:w-[75%] sm:w-[70%] w-full' : 'w-full'}`}>
                <Header setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
                {children}
            </div>
        </div>
    )
}

export default Wrapper
