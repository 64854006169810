export const itemMasterValidationRule = {
    itemLength: { isReq: true, regex: /^\d+(\.\d{0,2})?$/, minLen: 1, maxLen: 10 },
    itemWidth: { isReq: true, regex: /^\d+(\.\d{0,2})?$/, minLen: 1, maxLen: 10 },
    itemHeight: { isReq: true, regex: /^\d+(\.\d{0,2})?$/, minLen: 1, maxLen: 10 },
    itemWeight: { isReq: true, regex: /^\d+(\.\d{0,3})?$/, minLen: 1, maxLen: 10 },
    itemVolume: { isReq: true, regex: /^\d+(\.\d{0,2})?$/, minLen: 1, maxLen: 10 },
    taxOverrideAmt: { isReq: true, regex: "", minLen: 1, maxLen: 10 },
    divid: { isReq: true, regex: "", minLen: 30, maxLen: 40 },
    sgid: { isReq: true, regex: "", minLen: 30, maxLen: 40 },
    pname: { isReq: true, regex: "", minLen: 2, maxLen: 150 },
    remark: { isReq: false, regex: "", minLen: 1, maxLen: 200 },
    minRate: { isReq: true, regex: /^\d+(\.\d{0,2})?$/, minLen: 1, maxLen: 10 },
    maxRate: { isReq: true, regex: /^\d+(\.\d{0,2})?$/, minLen: 1, maxLen: 10 },
    itemDiscount: { isReq: true, regex: /^\d+(\.\d{0,2})?$/, minLen: 1, maxLen: 5 },
    itemRate: { isReq: true, regex: /^\d+(\.\d{0,2})?$/, minLen: 1, maxLen: 10 },
    itemMrp: { isReq: true, regex: /^\d+(\.\d{0,2})?$/, minLen: 1, maxLen: 10 },
    extin1: { isReq: true, regex: "", minLen: 1, maxLen: 50 },
    extin2: { isReq: true, regex: "", minLen: 1, maxLen: 50 },
    extin3: { isReq: true, regex: "", minLen: 1, maxLen: 50 },
    extin4: { isReq: true, regex: "", minLen: 1, maxLen: 50 },
    extin5: { isReq: true, regex: "", minLen: 1, maxLen: 50 },
    extin6: { isReq: true, regex: "", minLen: 1, maxLen: 50 },
    extin7: { isReq: true, regex: "", minLen: 1, maxLen: 50 },
    extin8: { isReq: true, regex: "", minLen: 1, maxLen: 50 },
    extin9: { isReq: true, regex: "", minLen: 1, maxLen: 50 },
    extin10: { isReq: true, regex: "", minLen: 1, maxLen: 50 },
};


export const priorityList = [
    {
        label: "General info",
        value: 0
    },
    {
        label: "High",
        value: 1
    },
    {
        label: "Mid",
        value: 2
    },
    {
        label: "Low",
        value: 3
    },
    {
        label: "Critical(Now)",
        value: 4
    }
]

export const executionTypeList = [
    {
        label: "Hour Based",
        value: 0
    },
    {
        label: "Daily",
        value: 1
    },
    {
        label: "Weekly",
        value: 2
    },
    {
        label: "Days",
        value: 3
    },
    {
        label: "Every Month",
        value: 4
    }
]
export const scheduleQueryType = [
    { label: "Exceute Query", value: 0 },
    { label: "Email", value: 1 },
    { label: "View Only Admin Panel", value: 2 },
    { label: "Execution File", value: 3 },
    { label: "Notification Query", value: 4 },
    { label: "Catalog Query", value: 6 }
]
export const weekdaysArray = [
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
    { label: "Sunday", value: 7 }
];

export const conditionTypeList = [{ label: "Only Days", value: 0 }, { label: "Only Never", value: 1 }, { label: "Both", value: 2 }]
