import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import IsLoadingHOC from "./common/Loader/Loader";
import { loginAction, changePassword } from "../redux/actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import loginImg3 from "../assets/img/login.svg";
import pointLogo from "../assets/img/the-point-logo.png"
const Changepassword = ({ close }) => {
  const [active, setActive] = useState("1");
  const [user, setUser] = useState({
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const getComponent = () => {
    switch (active) {
      case "1":
        return (
          <UserNameSection
            setActive={setActive}
            user={user}
            setUser={setUser}
          />
        );
      case "2":
        return (
          <PasswordSection
            setActive={setActive}
            setClose={close}
            password={user}
            setPassword={setUser}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div className="w-96 h-96 bg-white flex flex-col items-center justify-center gap-6 p-4 relative">
        <span
          className="absolute top-2.5 right-2.5 text-xl text-black cursor-pointer"
          onClick={() => close(false)}
        >
          &#x2715;
        </span>
        {getComponent()}
      </div>
    </div>
  );
};

const PasswordSection = ({ setActive, setClose, password, setPassword }) => {
  const [loading, setLoading] = useState(false);
  const doesMatch = useRef();
  const minLetter = useRef();

  const handleChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
    if (e.target.name === "newPassword") {
      if (password.newPassword.length < 5) {
        minLetter.current.classList.add("block");
        return;
      }
      minLetter.current.classList.remove("block");
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    if (password.newPassword !== password.confirmPassword) {
      doesMatch.current.classList.add("block");
      setLoading(false);
      return;
    }
    doesMatch.current.classList.remove("block");
    changePassword(password)
      .then((res) => {
        if (res.status === 1) {
          setClose(false);
          toast.success("Password Updated Successfully");
        } else toast.error(res.msg ? res.msg : res.message);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center gap-3 w-full">
      <span className="text-black text-2xl font-medium capitalize">
        {localStorage?.getItem("email")}
      </span>
      <input
        required
        type="text"
        name="oldPassword"
        value={password.oldPassword}
        onChange={handleChange}
        className="w-4/5 p-2"
        placeholder="Enter Old Password"
      />
      <div className="flex flex-col items-start justify-center gap-2 w-4/5">
        <input
          required
          type="password"
          name="newPassword"
          value={password.newPassword}
          onChange={handleChange}
          className="w-full p-2"
          placeholder="Enter New Password"
        />
        <span ref={minLetter} className="hidden text-xs text-red-500">
          *Password should be a minimum of 6 letters
        </span>
      </div>
      <div className="flex flex-col items-start justify-center gap-2 w-4/5">
        <input
          required
          type="password"
          className="w-full p-2"
          name="confirmPassword"
          value={password.confirmPassword}
          onChange={handleChange}
          placeholder="Please Confirm New Password"
        />
        <span ref={doesMatch} className="hidden text-xs text-red-500">
          *Password doesn't match
        </span>
      </div>
      <button
        onClick={handleSave}
        className="outline-none p-2.5 border-none bg-black text-white w-4/5 h-8 flex items-center justify-center"
      >
        {loading ? "Wait..." : "Confirm"}
      </button>
      <span
        className="absolute bottom-2.5 left-10 text-2xl text-black cursor-pointer"
        onClick={() => setActive("1")}
      >
        &#8249;
      </span>
    </div>
  );
};

const UserNameSection = ({ setActive, user, setUser }) => {
  const [loading, setLoading] = useState(false);
  const checkRef = useRef();

  const handleSave = (e) => {
    e.preventDefault();
    if (user.email === "") {
      checkRef.current.classList.add("block");
      setLoading(false);
      return;
    }
    checkRef.current.classList.remove("block");
    setActive("2");
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4 w-full">
      <span className="text-black text-2xl font-medium">User Name</span>
      <div className="text-black w-4/5">
        <div className="flex border border-black p-2">
          <i className="bi bi-person-fill"></i>
          <input
            type="text"
            value={user.email}
            name="email"
            className="border-none outline-none w-full"
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            maxLength="100"
            placeholder="Enter User Name"
            autoComplete="off"
          />
        </div>
        <span ref={checkRef} className="hidden text-xs text-red-500">
          *Username is required
        </span>
      </div>
      <button
        onClick={handleSave}
        className="outline-none p-2.5 border-none bg-black text-white w-4/5 h-8 flex items-center justify-center"
      >
        {loading ? "Wait" : "Confirm"}
      </button>
    </div>
  );
};

const Login = (props) => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [show, setShow] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const history = useHistory();

  const textChangeHandler = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setFormData({ ...formData, [key]: value });
  };

  const loginValidate = (e) => {
    e.preventDefault();
    if (formData.email === "" || formData.password === "") {
      return toast.info("Please enter your credentials");
    }
    props.setLoading(true);
    let requestBody = {
      email: formData.email,
      password: formData.password,
      longitude: "",
      latitude: "",
    };
    props
      .loginAction(requestBody)
      .then((data) => {
        if (data.status === 1) {
          toast.success(data.msg);
          let path = window.location.search;
          let route =
            window.location.protocol + "//" + window.location.host + "/";
          if (path) {
            let pathArr = path.split("/");
            if (path.match(/\/app/)) {
              route += pathArr[1];
            } else {
              pathArr.shift();
              route += pathArr.join("/");
            }
            if (route) {
              window.location.href = route;
            }
          } else {
            history.push("/");
          }
        } else {
          toast.error(data.msg);
        }
        props.setLoading(false);
      })
      .catch((err) => {
        toast.error(err.msg);
        props.setLoading(false);
      });
  };

  return (
    <div className="flex relative md:justify-start justify-center items-center w-full h-screen">
      <div className="absolute -z-20 h-full w-full">
        <div className="relative flex justify-end items-end h-full w-full">
          <img src={loginImg3} alt="" className="h-full w-1/2 object-contain" />
          <div className="absolute top-0 right-0 h-full w-full bg-gradient-to-t from-[#000000be] to-transparent"></div>
        </div>
      </div>
      {showChangePassword && <Changepassword close={setShowChangePassword} />}
      <form
        onSubmit={loginValidate}
        className="text-black bg-[#f3f3f3e0] border rounded lg:w-[35vw] md:w-[50vw] md:ml-20 w-[90%] lg:h-[80%] md:h-[90%] h-[65%] md:p-8 p-4 flex flex-col md:gap-4 gap-2"
        autoComplete="off"
      >
        <div className="flex flex-col justify-center items-start gap-2 mb-4 mt-10">
          <div className="flex items-center gap-3">
            <img src={pointLogo} className="h-12 rounded-md" />
            <h1 className="text-start md:text-4xl text-3xl uppercase">Log In</h1>
          </div>
          <h2 className="text-center md:text-xl text-base mt-2 ">
            Welcome to the POINT OF INDIA
          </h2>
        </div>
        <div className="flex flex-col md:text-base text-xs justify-center gap-4">
          <div className="mb-3 space-y-2">
            <span className="">Email*</span>
            <div className="">
              <input
                type="email"
                required
                value={formData.email}
                name="email"
                onChange={textChangeHandler}
                maxLength="100"
                placeholder="Enter User Name"
                autoComplete="off"
                className="w-full"
              />
            </div>
          </div>
          <div className="mb-3 space-y-2">
            <label className="">Password</label>
            <div className="border flex bg-white items-center">
              <input
                type={show ? "text" : "password"}
                value={formData.password}
                name="password"
                onChange={textChangeHandler}
                maxLength="100"
                placeholder="Password"
                autoComplete="off"
                className="w-full border-none"
              />
              {show ? (
                <FaRegEye
                  className="cursor-pointer text-xl text-[#00000081] mx-2"
                  onClick={() => setShow(false)}
                />
              ) : (
                <FaRegEyeSlash
                  className="cursor-pointer text-xl text-[#00000081] mx-2"
                  onClick={() => setShow(true)}
                />
              )}
            </div>
          </div>
          <div className="w-full flex items-center justify-between my-3">
            <div className="flex items-center gap-1.5 justify-center">
              <input type="checkbox" />
              <span className="">Remember me</span>
            </div>
            <div
              onClick={() => {
                setShowChangePassword(true);
                localStorage.setItem("component", 1);
              }}
            >
              <span className="cursor-pointer">Forget Password?</span>
            </div>
          </div>
          <button
            className="w-full bg-black text-white p-2 rounded"
            type="submit"
          >
            {props.loading ? "Loading..." : "Login"}
          </button>
        </div>
        <div className="text-[#575454] w-full md:text-[11px] text-[9px] mt-4">
          The admin panel is for authorized POINT OF INDIA Limited users to
          manage operations. Unauthorized access is prohibited.
        </div>
      </form>
    </div>
  );
};

export default connect(null, { loginAction })(IsLoadingHOC(Login, "Please Wait....."));