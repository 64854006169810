import React from 'react'

const Featured = () => {
    return (
        <div className='border h-[60vh] w-[30%] bg-gray-50 rounded-lg px-4 py-3'>
            <h3 className='text-lg text-gray-700'>Features Venue</h3>
        </div>
    )
}

export default Featured