import React, { useState, useEffect } from "react";
import IsLoadingHOC from "../common/Loader/Loader";
import { toast } from "react-toastify";
import AuthInput from "components/CustomElement/AuthInput";
import Heading from "../CustomElement/Heading";
import ToggleButton from "components/CustomElement/ToggleButton";
import Select from "react-select";
import { userGroups } from "data";
import { designationsList } from "data";
import { departments } from "data";
const defaultObj = {
	name: "",
	description: "",
	isActive: true,
	group: "",
	designation: "",
	department: "",

};

const CreateModule = (props) => {
	const [formData, setFormData] = useState(defaultObj);
	const [userDesignations, setUserDesignations] = useState(designationsList.map(i => ({ label: i.Name, value: i.ID })));
	const [userDepartment, setUserDepartment] = useState(departments.map(i => ({ label: i.Name, value: i.ID })));
	const handleSubmit = async () => {
		props.setLoading(true);
		try {
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setFormData(defaultObj);
			toast.success("Successfully saved!");
		} catch (err) {
			toast.error(`Error in saving`);
		} finally {
			props.setLoading(false);
		}
	};

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	}

	const handleToggle = () => {
		setFormData({ ...formData, isActive: !formData.isActive });
	}

	return (
		<div className="w-full text-sm bg-white p-6 rounded shadow border ">
			<Heading text={'Create User'} headingclass='text-xl' isButton={true} buttonText={'Create Now'} handleSubmit={handleSubmit} />
			<div className="grid md:grid-cols-3 sm:grid-cols-2 gap-8">
				<AuthInput label='Full Name' required={true} placeholder='Enter Full Name' name='name' maxLength={50} onChange={handleChange} value={formData.name} />
				<AuthInput label='Email' required={true} type='email' placeholder='Enter User Email' name='name' maxLength={50} onChange={handleChange} value={formData.name} />
				<AuthInput label='Phone' required={true} type='number' placeholder='Enter User Phone Number' name='name' maxLength={50} onChange={handleChange} value={formData.name} />
				<AuthInput label='About' name='about' placeholder='e.g. Akash will manage all the Posts being created !!' maxLength={80} onChange={handleChange} value={formData.description} />
				<div>
					<label className="block mb-1">Designation *</label>
					<Select
						onChange={(e) => setFormData({ ...formData, designation: e.value })}
						options={userDesignations}
						value={userDesignations.find((v) => v.value === formData.designation) || ""}
					/>
				</div>
				<div>
					<label className="block mb-1">Department *</label>
					<Select
						onChange={(e) => setFormData({ ...formData, department: e.value })}
						options={userDepartment}
						value={userDepartment.find((v) => v.value === formData.department)}
					/>
				</div>
				<ToggleButton handleToggle={handleToggle} isTrue={formData?.isActive} label='Active' />

			</div>
			<div>
				<Heading text='Assign Group' headingclass='mt-6' />
				{
					<Select options={userGroups.map(i => ({ label: i.Name, value: i.ID }))} value={formData.memberList} onChange={(e) => setFormData({ ...formData, memberList: e })} />
				}
			</div>
		</div>
	);
};

export default IsLoadingHOC(CreateModule, "Please Wait...");