import React, { useState, useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

const DateNav = () => {
    // Get the current date
    const currentDate = new Date();
    const [startDate, setStartDate] = useState(currentDate);

    // Generate an array of 14 days starting from the startDate
    const days = Array.from({ length: 14 }, (_, i) => {
        const day = new Date(startDate);
        day.setDate(day.getDate() + i);
        return day;
    });

    // Function to get the next or previous 14 days
    const changeDates = (direction) => {
        const newStartDate = new Date(startDate);
        if (direction === "prev") {
            newStartDate.setDate(newStartDate.getDate() - 14);
        } else if (direction === "next") {
            newStartDate.setDate(newStartDate.getDate() + 14);
        }
        setStartDate(newStartDate);
    };

    // Ensure the current date is always in view
    useEffect(() => {
        const currentDayIndex = days.findIndex(
            (day) => day.toDateString() === currentDate.toDateString()
        );

        // If the current date is at the end (position 13), shift the drawer forward
        if (currentDayIndex === 13) {
            const newStartDate = new Date(startDate);
            newStartDate.setDate(newStartDate.getDate() + 1);
            setStartDate(newStartDate);
        }

        // If the current date is at the start (position 0), shift the drawer backward
        if (currentDayIndex === 0) {
            const newStartDate = new Date(startDate);
            newStartDate.setDate(newStartDate.getDate() - 1);
            setStartDate(newStartDate);
        }
    }, [currentDate, startDate]);

    return (
        <div className="w-full flex flex-col items-center justify-center space-y-4 p-4 border-y bg-white">
            <div className="w-full flex items-center justify-center space-x-4">
                <button onClick={() => changeDates("prev")}>
                    <MdKeyboardArrowRight className="rotate-180 text-xl" />
                </button>
                <h2 className="text-lg font-semibold">
                    {startDate.toLocaleString("default", {
                        month: "long",
                        year: "numeric",
                    })}
                </h2>
                <button onClick={() => changeDates("next")}>
                    <MdKeyboardArrowRight className="text-xl" />
                </button>
            </div>
            <div className="w-full flex items-center justify-center space-x-10 overflow-x-auto p-2">
                {days.map((day, index) => (
                    <div
                        key={index}
                        className={`flex flex-col items-center px-3 py-3 rounded-full border transition-all shadow text-sm font-medium
                            ${day.toDateString() === currentDate.toDateString()
                                ? "bg-purple-600 text-white"
                                : "bg-gray-50 text-gray-600"
                            }`}
                    >
                        <span>{day.getDate()}</span>
                        <span className="text-xs mt-[2px]">
                            {day.toLocaleString("default", { weekday: "short" })}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DateNav;