import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect, useSelector } from "react-redux";
import PrivateRoute from "./routes/PrivateRoute";
import Login from "./components/Login";
import Screens from "./components/page.config";
import PageNotFound from "./components/PageNotFound/PageNotFound";
const App = () => {
	const user_data = useSelector((state) => state.user);
	const token = useSelector((state) => {
		if (state.user) {
			if (state.user.token) {
				return state.user.token.accessToken;
			} else {
				return null;
			}
		} else {
			return null;
		}
	});
	window.jwtParser = (token) => {
		if (token) {
			try {
				return JSON.parse(window.atob(token.split(".")[1]));
			} catch (e) {
				return null;
			}
		} else {
			return {};
		}
	};
	window.convertFileToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = reject;
		});
	};
	window.IsJsonString = (str) => {
		try {
			let json = JSON.parse(str);
			return typeof json === "object";
		} catch (e) {
			return false;
		}
	};


	return (
		<>
			<BrowserRouter>
				<Switch>
					<Route exact path="/login" component={Login} />
					<PrivateRoute exact={true} path={"/"} component={Screens.Dashboard} icon={"nc-icon nc-bank"} location={"/"} name="Dashboard" />;
					{user_data?.routes?.map((v, i) => {
						return <PrivateRoute key={i} exact={v.link === "/" ? true : false} path={v.link} component={Screens[v.component]} name={v.name} moduleId={v.moduleId} />;
					})}
					{!token ? <Redirect to={`/login?route=${window.location.pathname}${window.location.search !== "" ? `${window.location.search}` : ""}`} /> : ""}
					<Route path="*" component={PageNotFound} />
				</Switch>
			</BrowserRouter>
			<ToastContainer position="top-right" autoClose={5000} theme="colored" hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
		</>
	);
};
export default connect(null, {})(App);
