import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import UserPng from "../../assets/img/User-Profile-PNG.png"; // Update this path based on your project structure
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../redux/actions";
const getColorFromName = (name) => {
    if (!name) return "#00000038"; // Default background

    const firstChar = name.charCodeAt(0) || 0;
    const lastChar = name.charCodeAt(name.length - 1) || 0;

    // Generate a color from ASCII values
    const r = (firstChar * 5) % 256;
    const g = (lastChar * 7) % 256;
    const b = ((firstChar + lastChar) * 3) % 256;

    return `rgb(${r}, ${g}, ${b})`;
};

const UserProfile = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const user = useSelector((state) => state.user);
    let userdata = user.userInfo ?? {};
    const bgColor = useMemo(() => getColorFromName(userdata.name), [userdata.name]);
    const dispatch = useDispatch();
    const logoutButton = (e) => {
        dispatch(logoutAction());
    };
    return (
        <nav className="flex items-center justify-center">
            <div className="relative flex justify-between items-center gap-4">
                
                <div className="flex items-center gap-2" onClick={() => setDropdownOpen(!dropdownOpen)}>
                    <div
                        className="cursor-pointer p-2 border rounded-full flex items-center justify-center w-8 h-8"
                        style={{ backgroundColor: userdata.profile ? "transparent" : bgColor }}
                    >
                        {!userdata.profile && userdata.name ? (
                            <span className="text-white text-sm font-medium">
                                {userdata.name[0].toUpperCase()}
                                {userdata.name[userdata.name.length - 1].toUpperCase()}
                            </span>
                        ) : null}
                    </div>
                    <h3 className="text-sm text-[#454444]">{userdata.name ?? "User"}</h3>
                </div>

                {/* Dropdown Menu */}
                {dropdownOpen && (
                    <div className="absolute right-0 mt-2 top-10 w-72 z-[9999999] px-4 py-2 bg-white border border-gray-200 rounded shadow-lg">
                        <div className="flex items-start border-b py-2">
                            <img
                                src={userdata?.profile}
                                alt="Profile"
                                onError={(e) => (e.target.src = UserPng)}
                                className="h-8 w-8 rounded-full object-cover"
                            />
                            <div className="ml-3">
                                <p className="text-sm font-semibold text-gray-900">{userdata.name}</p>
                                <p className="text-sm text-gray-500">@{userdata.username}</p>
                            </div>
                        </div>
                        <Link to="/login" onClick={logoutButton} className="flex items-center justify-center gap-3 py-2">
                            <FiLogOut />
                            <span>Logout</span>
                        </Link>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default UserProfile;
