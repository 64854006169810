import React, { Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "../layouts/Main"
const PrivateRoute = ({ accessToken, component: Component, AsanaTaskLink, moduleId, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				accessToken && accessToken !== "" ?
					<Layout AsanaTaskLink={AsanaTaskLink} {...props}>
						<Suspense fallback={<div className="w-full flex items-center justify-center h-[70vh]">Loading...</div>}>
							<Component moduleId={moduleId} />
						</Suspense>
					</Layout>

					: (
						<Redirect to={`/login${window.location.pathname ? `?route=${window.location.pathname}` : ""}${window.location.search ? window.location.search : ""}`} />
					)
			}
		/>
	);
};
const mapStateToProps = ({ user }) => {
	const { token } = user;
	if (token) {
		return { accessToken: token.accessToken };
	} else {
		return { accessToken: "" };
	}
};

export default connect(mapStateToProps)(PrivateRoute);
