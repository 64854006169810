import React, { useState } from "react";
import "./loader.css";
import Modal from "components/common/Modal";

const Loading = (props) => {
    return (
        <Modal isCloseAble={false}>
            <div className="banter-loader">
                <div className="banter-loader__box"></div>
                <div className="banter-loader__box"></div>
                <div className="banter-loader__box"></div>
                <div className="banter-loader__box"></div>
                <div className="banter-loader__box"></div>
                <div className="banter-loader__box"></div>
                <div className="banter-loader__box"></div>
                <div className="banter-loader__box"></div>
                <div className="banter-loader__box"></div>
            </div>
        </Modal>
    );
};


export const IsLoadingHOC = (WrappedComponent, loadingMessage) => {
    function HOC(props) {
        const [isLoading, setLoading] = useState(false);

        const setLoadingState = (isComponentLoading) => {
            setLoading(isComponentLoading);
        };

        return (
            <>
                {isLoading && <Loading message={loadingMessage} />}
                <WrappedComponent {...props} setLoading={setLoadingState} loading={isLoading} />
            </>
        );
    }
    return HOC;
};

export default IsLoadingHOC;