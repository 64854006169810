import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DateNav from './Dashboard/DateNav';
import Featured from './Dashboard/Featured';
import Events from './Dashboard/Events';
import Drafts from './Dashboard/Drafts';

const quotes = [
    "The only limit to our realization of tomorrow is our doubts of today.",
    "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    "Hardships often prepare ordinary people for an extraordinary destiny.",
    "Success usually comes to those who are too busy to be looking for it.",
    "Don't let yesterday take up too much of today.",
    "We cannot solve problems with the kind of thinking we employed when we came up with them.",
    "Learn as if you will live forever, live like you will die tomorrow.",
    "When you change your thoughts, remember to also change your world.",
    "It is better to fail in originality than to succeed in imitation.",
    "The road to success and the road to failure are almost exactly the same.",
    "I never dreamed about success. I worked for it.",
    "Success is getting what you want; happiness is wanting what you get."
];

const Dashboard = () => {
    const user = useSelector((state) => state.user);
    let userdata = user.userInfo ?? {};
    return (
        <div className="w-full text-xs flex flex-col ">
            <DateNav />
            <div className=" w-full bg-white p-4 flex items-start gap-4">
                <Featured />
                <Events />
                <div className="w-[40%]">
                    <Drafts />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
