import { SAVE_USER_INFO, SAVE_ACCESS_TOKEN, SAVE_SIDE_MENU, LOG_OUT, SAVE_TOKEN_LIST, SAVE_ROUTES } from "../types/types";

const initialState = {
	token: { accessToken: "", refreshToken: "" },
	userInfo: {},
	sideMenu: [],
	tokenList: {},
	routes: [],
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_ACCESS_TOKEN: {
			return { ...state, token: action.payload };
		}
		case SAVE_USER_INFO: {
			let userInfo = { ...action.payload };
			return { ...state, userInfo: userInfo };
		}
		case SAVE_SIDE_MENU: {
			return { ...state, sideMenu: action.payload };
		}
		case SAVE_ROUTES: {
			return { ...state, routes: action.payload };
		}
		case SAVE_TOKEN_LIST: {
			let tokenList = Object.assign({}, state.tokenList, action.payload);
			return { ...state, tokenList: tokenList };
		}
		case LOG_OUT: {
			return { ...initialState };
		}
		default:
			return state;
	}
};
export default userReducer;
