import React from 'react'

export default function Modal({ children, handleClose, className, isCloseAble = true }) {
    return (
        <div className='w-full fixed z-[9999] inset-0 bg-black bg-opacity-50 flex justify-center items-center' onClick={isCloseAble ? () => { } : handleClose}>
            <div onClick={(e) => e.stopPropagation()} className={isCloseAble ? `bg-white p-4 rounded-md ${className} ` : ''}>
                {children}
            </div>
        </div>
    )
}
