import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";

function SidebarMenu(props) {
  const location = useLocation();
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    let filteredMenuList = props.menulist.filter(
      (v) => v?.ID?.toUpperCase() !== "0350215F-A87D-4857-BCFD-48C15D38F4BB"
    );

    for (let i in filteredMenuList) {
      if (filteredMenuList[i].link === "/") {
        filteredMenuList[i].ChildList = [];
        filteredMenuList[i].isExpand = !filteredMenuList[i].isExpand;
      } else if (location.pathname.indexOf(filteredMenuList[i].link) > -1 && filteredMenuList[i].link) {
        filteredMenuList[i].isExpand = !filteredMenuList[i].isExpand;
      } else {
        filteredMenuList[i].isExpand = false;
      }
    }

    setMenuList(filteredMenuList);
  }, [props.menulist, location.pathname]);

  const toggleMenu = (key) => {
    const updatedList = menuList.map((item, index) => ({
      ...item,
      isExpand: index === key ? !item.isExpand : false,
    }));
    setMenuList(updatedList);
  };

  const toggleSubMenu = (parentIndex, childIndex) => {
    const updatedList = [...menuList];
    updatedList[parentIndex].ChildList[childIndex].isExpand = !updatedList[parentIndex].ChildList[childIndex].isExpand;
    setMenuList(updatedList);
  };

  return (
    <div className="w-full h-full px-1 overflow-scroll custom-scrollbar">
      <nav className="font-thin tracking-wider">
        <NavLink
          to={'/'}
          className={`py-2 pl-4 mt-1 hover:text-white hover:bg-[#191818] flex items-center justify-between rounded text-sm px-2 font-medium hover:shadow`}
        >
          Dashboard
        </NavLink>
        {menuList.map((prop, key) => (
          <div key={key}>
            <NavLink
              to={prop.ChildList && prop.ChildList.length > 0 ? "#" : prop.link}
              className={`py-2 mt-3 pl-4 flex items-center justify-between rounded text-xs px-2 font-medium hover:shadow hover:text-white hover:bg-[#191818]`}
              onClick={() => toggleMenu(key)}
            >
              <span className="text-sm">{prop.name}</span>
              {prop.ChildList && prop.ChildList.length > 0 && (
                <span className={`float-right ${prop.isExpand ? "transform rotate-90" : ""}`}>
                  <MdArrowForwardIos className="text-xs" />
                </span>
              )}
            </NavLink>
            {prop.ChildList && prop.ChildList.length > 0 && prop.isExpand && (
              <div className="ml-2 text-xs">
                {prop.ChildList.map((sub, i) => (
                  <div key={`${key}-${i}`}>
                    <NavLink
                      to={sub.ChildList && sub.ChildList.length > 0 ? "#" : `${prop.link}${sub.link}`}
                      className={`py-2 mt-2 pl-4 flex items-center justify-between rounded text-xs px-2 font-medium hover:shadow hover:text-white hover:bg-[#262525]`}
                      onClick={() => toggleSubMenu(key, i)}
                    >
                      <span className="ml-4 text-xs">{sub.Name}</span>
                      {sub.ChildList && sub.ChildList.length > 0 && (
                        <span className={`float-right ${sub.isExpand ? "transform rotate-90" : ""}`}>
                          <MdArrowForwardIos className="text-xs" />
                        </span>
                      )}
                    </NavLink>
                    {sub.ChildList && sub.ChildList.length > 0 && sub.isExpand && (
                      <div className="ml-8 text-xs">
                        {sub.ChildList.map((sub2, j) => (
                          <NavLink
                            key={`${key}-${i}-${j}`}
                            to={`${prop.link}${sub.link}${sub2.link}`}
                            className="py-2 mt-1 pl-4 flex items-center justify-between rounded text-xs px-2 font-medium hover:shadow hover:text-white hover:bg-[#4e4e4e]"
                          >
                            {sub2.Name}
                          </NavLink>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>
    </div>
  );
}

export default SidebarMenu;
