import React from 'react';
import Dashboard from 'components/Dashboard.jsx';
import CreateGroup from './GroupManagement/CreateGroup.jsx';
import EditGroup from './GroupManagement/EditGroup.jsx';
import PermissionManagement from './PermissionManagement.jsx';
import CreateUser from './UserManagement/CreateUser.jsx';
import EditUser from './UserManagement/EditUser.jsx';
import CreateArticle from './ArticleManagement/CreateArticle.jsx';
const EditModule = React.lazy(() => import("./ModulesManagement/EditModule.jsx"));
const CreateModule = React.lazy(() => import("./ModulesManagement/CreateModule.jsx"));
const Screens = {
    Dashboard,
    EditModule,
    CreateModule,
    CreateGroup,
    EditGroup,
    PermissionManagement,
    CreateUser,
    EditUser,
    CreateArticle
};
export default Screens;