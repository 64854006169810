import { SAVE_SIDE_MENU, SAVE_ACCESS_TOKEN, SAVE_USER_INFO, LOG_OUT, SAVE_TOKEN_LIST, SAVE_ROUTES } from "../types/types";
import api from "../../api/api";
import { moduleDBList } from "data";
import { loginResults } from "data";

const generateMenuAndRoutes = (moduleDBList) => {
	const menu = moduleDBList.map((v) => ({
		ID: v.ID,
		link: v.link,
		name: v.Name,
		component: v.linkVal,
		type: v.type,
		ChildList: v.ChildList || [],
	}));


	let routes = [];

	const traverseMenu = (menuList, parentPath = "") => {
		menuList.forEach((item) => {
			const fullPath = `${parentPath}${item.link}`;
			if (item.ChildList && item.ChildList.length > 0) traverseMenu(item.ChildList, fullPath);
			routes.push({
				link: fullPath,
				name: item.Name,
				WebLink: item.WebLink || "",
				component: item.component || item.linkVal,
				AsanaTaskLink: item.AsanaTaskLink || "",
				moduleId: item.ID
			});
		});
	};
	traverseMenu(menu);
	return { menu, routes };
};

// Usage



export const loginAction = (data) => async (dispatch) => {
	return new Promise(async (resolved, reject) => {
		const result = loginResults;
		if (result.status === 1) {
			dispatch(saveAccessToken({ accessToken: result.data.access_token, refreshToken: result.data.refresh_token }));
			localStorage.setItem("userData", JSON.stringify({ accessToken: result.data.access_token, refreshToken: result.data.refresh_token }));
			dispatch(saveUserinfo(result.data.userdata));
			if (Array.isArray(result.data.userdata.userRights.permissionArr)) {
				const { menu, routes } = generateMenuAndRoutes(moduleDBList);
				dispatch(saveRoutes(routes));
				dispatch(saveSideMeny(menu));
			} else {
				dispatch(saveSideMeny([]));
			}
			let tokenList = { [result.data.userdata.companyid]: { companyid: result.data.userdata.companyid, dbname: result.data.userdata.DBName, companyname: result.data.userdata.companyname, accessToken: result.data.access_token, refreshToken: result.data.refresh_token } };
			dispatch(saveTokenList(tokenList));
			resolved(result);
		} else {
			reject(result);
		}
	});
};

export const logoutAction = () => async (dispatch) => {
	try {
		await api.post("/logout", {});
	} catch (err) {
		console.log("error", err);
	}
	dispatch(logout());
	return true;
};

export const saveAccessToken = (token) => {
	return { type: SAVE_ACCESS_TOKEN, payload: token };
};

export const logout = () => ({
	type: LOG_OUT,
	payload: {},
});

export const saveSideMeny = (menuList) => ({ type: SAVE_SIDE_MENU, payload: menuList });

export const saveRoutes = (routesList) => ({ type: SAVE_ROUTES, payload: routesList });

export const saveUserinfo = (data) => ({ type: SAVE_USER_INFO, payload: data });

export const saveTokenList = (data) => ({ type: SAVE_TOKEN_LIST, payload: data });

export const refreshTokenAction = (body) => {
	return api.post(`/refresh-token`, body);
};
export const changePassword = (body) => {
	return api.post(`/change-password`, body);
};

