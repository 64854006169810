import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { refreshTokenAction } from "../../redux/actions";
import { BiMenuAltLeft } from "react-icons/bi";
import IsLoadingHOC from "../../components/common/Loader/Loader";
import UserProfile from "./UserProfile";
const Header = ({ setShowSidebar, showSidebar, ...props }) => {
	const [countDown, setCountDown] = useState(null);
	const dispatch = useDispatch();
	// useEffect(() => {
	// 	if (!countDown) {
	// 		refreshTokenHandle();
	// 		setCountDown(new Date().getTime());
	// 	} else {
	// 		const interval = setInterval(() => {
	// 			setCountDown(new Date().getTime());
	// 			refreshTokenHandle();
	// 		}, 1800000);
	// 		return () => clearInterval(interval);
	// 	}
	// 	// eslint-disable-next-line
	// }, [countDown]);

	// const refreshTokenHandle = () => {
	// 	let token = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {};
	// 	if (token.refreshToken) {
	// 		props.setLoading(true);
	// 		refreshTokenAction({ refreshToken: token.refreshToken })
	// 			.then((d) => {
	// 				localStorage.setItem("userData", JSON.stringify({ accessToken: d.data.access_token, refreshToken: d.data.refresh_token }));
	// 				props.setLoading(false);
	// 			})
	// 			.catch((err) => {
	// 				localStorage.removeItem("userData");
	// 				dispatch({ type: "LOG_OUT" });
	// 				props.setLoading(false);
	// 			});
	// 	} else {
	// 		dispatch({ type: "LOG_OUT" });
	// 	}
	// };

	return (
		<div className="w-full flex items-center bg-white pb-4 pt-7 justify-between px-4 py-2.5 text-xs ">

			{!showSidebar ? (
				<div className="flex items-center gap-2">
					<button className="px-4 py-[0.30rem] text-sm text-[#2b2a2a]  rounded flex items-center justify-center gap-1" onClick={() => setShowSidebar((state) => !state)}>
						<BiMenuAltLeft className="text-lg" />
						<span className="text-sm">
							Explore
						</span>
					</button>
				</div>
			) : (
				<div></div>
			)}

			<UserProfile />
		</div>
	);
};

export default IsLoadingHOC(Header, "Refreshing Token Please Wait");
