import React from "react";

const ToggleButton = ({ label, handleToggle, isTrue }) => {
    return (

        <div className={`relative rounded-md`}>
            {label && (
                <div className='flex items-center'>
                    <label
                        className="text-sm font-ligh text-gray-700 capitalize"
                    >
                        {label}
                    </label>

                </div>
            )}
            <div
                className={`flex items-center cursor-pointer ${label ? 'mt-2' : ''}`}
                onClick={handleToggle}
            >

                <div
                    className={`w-10 h-5 flex items-center rounded-full p-1 transition-all duration-300 ${isTrue ? "bg-blue-700" : "bg-gray-300"}`}>
                    <div className={`w-6 h-6 rounded-full flex items-center justify-center shadow-md transition-all duration-300 ${isTrue ? "translate-x-4 bg-white" : "-translate-x-1 bg-gray-100"}`}>
                        {isTrue ? (
                            <span className="text-blue-700 text-lg"></span>
                        ) : (
                            <span className="text-red-700 text-lg"></span>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ToggleButton;
