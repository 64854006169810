import axios from "axios";
import { store } from "../redux/store";
import { saveAccessToken } from "../redux/actions";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
axios.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {};
        const accessToken = token.accessToken ? token.accessToken : "";
        if (accessToken) {
            config.headers["Authorization"] = accessToken;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        let token = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {};

        const refreshToken = token.refreshToken ? token.refreshToken : "";
        if (originalRequest.url !== `${baseUrl}/login` && error.response) {
            if (refreshToken && error.response.data.status === -1 && !originalRequest._retry) {
                originalRequest._retry = true;
                axios
                    .post(`${baseUrl}/refresh-token`, { refreshToken: refreshToken })
                    .then((res) => {
                        if (res["data"]) {
                            if (res.data.status === 1) {
                                store.dispatch(saveAccessToken(res.data.data));
                                localStorage.setItem("userData", JSON.stringify({ accessToken: res.data.data.access_token, refreshToken: res.data.data.refresh_token }));
                                return axios(originalRequest);
                            }
                        } else {
                            store.dispatch({ type: "LOG_OUT" });
                        }
                    })
                    .catch((error2) => {
                        store.dispatch({ type: "LOG_OUT" });
                        return Promise.reject(error2);
                    });
            }
        }
        return Promise.reject(error);
    }
);
class Api {
    post(path, data = null) {
        return this.send(path, "post", data);
    }

    put(path, data = null) {
        return this.send(path, "put", data);
    }

    get(path, data = null) {
        return this.send(path, "get", data);
    }

    delete(path, data = null) {
        return this.send(path, "delete", data);
    }

    send(url, method, data) {
        let uri = `${baseUrl}${url}`;
        return new Promise((resolve, reject) => {
            let option = { method, url: uri, data: data };
            axios(option)
                .then((response) => {
                    return response.data;
                })
                .then((responseData) => {
                    resolve(responseData);
                })
                .catch((error) => {
                    if (error["response"]) {
                        if (error.response.status === 401) {
                            store.dispatch(saveAccessToken(null));
                        }
                        resolve(error.response.data);
                    } else {
                        resolve(error);
                    }
                });
        });
    }
}

const api = new Api();
export default api;
