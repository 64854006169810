import AuthInput from 'components/CustomElement/AuthInput';
import Editor from 'components/CustomElement/Editor/Editor'
import Heading from 'components/CustomElement/Heading'
import { HiOutlineExternalLink } from "react-icons/hi";
import React, { useState } from 'react'
import { FaRegEye, FaRegImage } from 'react-icons/fa6';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaExpandArrowsAlt, FaSave } from 'react-icons/fa';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import ToggleButton from 'components/CustomElement/ToggleButton';
import { RxCross2 } from "react-icons/rx";
import placeHolderImage from '../../assets/img/image-placeholder-image.webp'
import { departments } from 'data';
import { licenses } from 'data';
const CreateArticle = () => {
    const [data, setData] = useState('');
    const [artSection, setArtSection] = useState('content');
    const [saveOption, setSaveOption] = useState('Publish');
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [showSaveOptions, setShowSaveOptions] = useState(false);
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            setTags([...tags, inputValue.trim()]);
            setInputValue('');
        }
    };

    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };

    console.log('departmets--->', departments);
    const handleSaveOption = (option) => {
        setSaveOption(option);
        setShowSaveOptions(false);
    }
    return (
        <div className='w-full flex items-center flex-col mb-5'>
            <div className="w-full flex items-center justify-between bg-gray-200 p-4">
                <div className="flex items-center justify-start gap-4">
                    <div className=" flex items-center cursor-pointer text-sm justify-center px-4 py-[0.4rem] gap-1 border border-[#4f5af02f] text-[#0c39eddb] bg-[#4f5af02f]">
                        <FaRegEye />
                        <span>Preview</span>
                    </div>
                    <div className=" flex items-center text-sm cursor-pointer justify-center px-6 py-[0.4rem] gap-1 border text-[#545557ec] border-[#54555760]">
                        Versions
                    </div>
                    <div className="flex items-center gap-1 text-[#6d6b6b] text-xs cursor-pointer ">
                        <AiOutlineInfoCircle className='text-sm' />
                        <span className=''>Help</span>
                    </div>
                </div>
                <div className="flex items-center justify-end gap-4">
                    <div className=" flex items-center cursor-pointer bg-white text-sm justify-center px-6 py-[0.4rem] gap-1 border text-[#545557ec] border-[#54555760]">
                        Close
                    </div>
                    <div className="flex relative transition-all">
                        <div className="flex cursor-pointer items-center text-sm justify-center px-4 py-[0.4rem] gap-2 border border-[#ffffff2f] bg-[#0636f6] text-[#ffffff]">
                            <FaSave className='text-base' />
                            <span>{saveOption}</span>
                        </div>
                        <div className="flex cursor-s-resize border-l items-center text-sm justify-center px-1 py-[0.4rem] gap-2 border border-[#4f5af02f] bg-[#0636f6] text-[#ffffff]" onClick={() => setShowSaveOptions(!showSaveOptions)}>
                            <MdOutlineKeyboardArrowDown className='text-base ' />
                        </div>
                        {
                            showSaveOptions ?
                                <div className="max-w-40 min-h-20 flex items-center flex-col bg-white shadow rounded-b border absolute top-[110%] right-[0%]">
                                    <h2 className='w-full text-start px-3 py-1 text-sm cursor-pointer hover:bg-gray-100 transition-all' onClick={() => handleSaveOption('Publish')}>Publish</h2>
                                    <h2 className='w-full text-start px-3 py-1 text-sm cursor-pointer hover:bg-gray-100 transition-all' onClick={() => handleSaveOption('Draft')}>Draft</h2>
                                    <h2 className='w-full text-start px-3 py-1 text-sm cursor-pointer hover:bg-gray-100 transition-all' onClick={() => handleSaveOption('UnPublished')}>Unpublished</h2>
                                </div>
                                : ''
                        }
                    </div>

                </div>
            </div>
            <div className="w-full flex p-4 gap-3 items-center  ">
                <div className="w-[70%]">
                    <AuthInput className='w-full mb-2 py-[0.60rem] px-3 text-sm mt-1 border-gray-200' label='Title' required={true} placeholder='E.g. How to make a beautiful career !!' />
                </div>
                <div className="flex border-gray-200 items-center justify-between py-[0.60rem] border px-3 w-[30%] rounded mt-5 bg-white">
                    <div className=" w-[70%] flex items-center cursor-pointer text-sm justify-center gap-4">
                        <FaRegEye />
                        <span>Live Preview</span>
                    </div>
                    <HiOutlineExternalLink className='text-[#0c39eddb]' />
                </div>
            </div>
            <div className="w-full text-sm rounded flex items-start gap-3 px-4">
                <div className="w-[70%]">
                    <div className="w-full flex items-start flex-col">
                        <div className="flex gap-2 text-sm items-center ">
                            <span className={`border-b-2 transition-all ${artSection === 'content' ? 'border-[#0c39eddb] text-gray-700' : 'border-[#f3f4f6] text-gray-500'}  px-4 pb-[0.1rem] cursor-pointer`} onClick={() => setArtSection('content')}>Content</span>
                            <span className={`border-b-2 transition-all ${artSection === 'options' ? 'border-[#0c39eddb] text-gray-700' : 'border-[#f3f4f6] text-gray-500'}  px-4 pb-[0.1rem] cursor-pointer`} onClick={() => setArtSection('options')}>Options</span>
                            <span className={`border-b-2 transition-all ${artSection === 'publishing' ? 'border-[#0c39eddb] text-gray-700' : 'border-[#f3f4f6] text-gray-500'}  px-4 pb-[0.1rem] cursor-pointer`} onClick={() => setArtSection('publishing')}>Publishing</span>
                            <span className={`border-b-2 transition-all ${artSection === 'permissions' ? 'border-[#0c39eddb] text-gray-700' : 'border-[#f3f4f6] text-gray-500'}  px-4 pb-[0.1rem] cursor-pointer`} onClick={() => setArtSection('permissions')}>Permissions</span>
                        </div>
                        {
                            artSection === 'content' && <div className="w-full mt-1">
                                <div className="py-3 px-4 w-full bg-white text-gray-600 border-b flex items-center justify-between">
                                    <div className="flex items-center gap-8">
                                        <h2 className='text-sm text-gray-500 '>Body text</h2>
                                        {/* <div className='flex items-center gap-4 text-sm text-gray-500 border-r px-6'>
                                        <span className='font-semibold'>B</span>
                                        <span className='italic font-medium'>i</span>
                                        <span className={`after:content-[''] after:absolute after:h-[2px] after:w-3 after:bg-gray-500 after:bottom-0 relative flex flex-col items-center`}>U</span>
                                    </div> */}
                                    </div>
                                    <div className="">
                                        <FaExpandArrowsAlt className='text-green-600' />
                                    </div>
                                </div>
                                <Editor value={data} onChange={setData} holder="editorjs-container" className='w-full bg-white p-3 min-h-[50vh]' />
                            </div>
                        }
                        {
                            artSection === 'options' && <div className="w-full mt-1">
                                <div className="py-3 px-4 w-full bg-white text-gray-600 border-b flex items-center justify-between">
                                    <div className="flex items-center gap-8">
                                        <h2 className='text-sm text-gray-700 '>Options</h2>
                                        {/* <div className='flex items-center gap-4 text-sm text-gray-500 border-r px-6'>
                                        <span className='font-semibold'>B</span>
                                        <span className='italic font-medium'>i</span>
                                        <span className={`after:content-[''] after:absolute after:h-[2px] after:w-3 after:bg-gray-500 after:bottom-0 relative flex flex-col items-center`}>U</span>
                                    </div> */}
                                    </div>
                                    <div className="">
                                        <FaExpandArrowsAlt className='text-green-600' />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            artSection === 'publishing' && <div className="w-full mt-1">
                                <div className="py-3 px-4 w-full bg-white text-gray-600 border-b flex items-center justify-between">
                                    <div className="flex items-center gap-8">
                                        <h2 className='text-sm text-gray-700 '>Publishing</h2>
                                        {/* <div className='flex items-center gap-4 text-sm text-gray-500 border-r px-6'>
                                        <span className='font-semibold'>B</span>
                                        <span className='italic font-medium'>i</span>
                                        <span className={`after:content-[''] after:absolute after:h-[2px] after:w-3 after:bg-gray-500 after:bottom-0 relative flex flex-col items-center`}>U</span>
                                    </div> */}
                                    </div>
                                    <div className="">
                                        <FaExpandArrowsAlt className='text-green-600' />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            artSection === 'permissions' && <div className="w-full mt-1">
                                <div className="py-3 px-4 w-full bg-white text-gray-600 border-b flex items-center justify-between">
                                    <div className="flex items-center gap-8">
                                        <h2 className='text-sm text-gray-700 '>Permissions</h2>
                                        {/* <div className='flex items-center gap-4 text-sm text-gray-500 border-r px-6'>
                                        <span className='font-semibold'>B</span>
                                        <span className='italic font-medium'>i</span>
                                        <span className={`after:content-[''] after:absolute after:h-[2px] after:w-3 after:bg-gray-500 after:bottom-0 relative flex flex-col items-center`}>U</span>
                                    </div> */}
                                    </div>
                                    <div className="">
                                        <FaExpandArrowsAlt className='text-green-600' />
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    <div className="w-full mt-4">
                        <Heading text='MetaData' isBPM={false} />
                        <div className='bg-white p-4 mt-2 border'>
                            <div className="">
                                <label htmlFor="" className='text-gray-500 text-sm'>Description*</label>
                                <textarea name="" className='border w-full mt-1 h-24 resize-none bg-gray-50 p-2 ' id="" placeholder='Please give a Meta description !!'></textarea>
                            </div>
                            <div className="mt-2">
                                <label htmlFor="" className='text-gray-500 text-sm'>Meta Keywords</label>
                                <textarea name="" className='border w-full mt-1 h-24 resize-none bg-gray-50 p-2 ' placeholder='e.g. techonology, python, html' id=""></textarea>
                            </div>

                            <div className="mt-5">
                                <h2 className='text-gray-700 w-[30%]'>License *</h2>
                                <div className=" flex items-center bg-white border justify-start gap-4 px-2 py-3 mt-1">
                                    <select className='w-full outline-none text-gray-500'>
                                        {
                                            licenses.map(i => (
                                                <option className='text-gray-600'>{i?.name}</option>

                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="w-[30%]">
                    <div className="bg-white border mt-7">
                        <div className="py-3 px-3">
                            <h2 className='border-b pb-1 px-2 text-gray-500'>Article Basics</h2>
                            <div className="w-full mt-3 text-gray-400">
                                <AuthInput className='w-full mb-2 py-[0.60rem] px-3 text-sm mt-1 border-gray-200' label='Sub Title' placeholder='E.g. How to make a beautiful career !!' />
                            </div>
                            <div className="mt-2">
                                <label htmlFor="" className='text-gray-500 text-sm'>Description</label>
                                <textarea name="" className='border w-full mt-1 h-24 resize-none bg-gray-50 p-2 ' placeholder='e.g. techonology, python, html' id=""></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white border mt-4">
                        <div className="py-3 px-3">
                            <h2 className='border-b pb-1 px-2 text-gray-500'>Alias *</h2>
                            <div className="mt-2 flex items-center justify-between px-2 py-3 ">
                                <h2 className='text-gray-600'>How to Make beautiful life</h2>
                                <FiEdit />
                            </div>
                            <div className=" flex items-center justify-start gap-4 px-2 mt-4">
                                <h2 className='text-gray-500 w-[30%]'>Featured</h2>
                                <div className="flex gap-2">
                                    <ToggleButton isTrue={true} />
                                    <span className='text-gray-600'>Yes</span>
                                </div>
                            </div>
                            <div className=" flex items-start justify-center flex-col gap-4 px-2 mt-5">
                                <h2 className='text-gray-500 w-[30%]'>Status</h2>
                                <div className="flex items-center gap-4">
                                    <div className="flex gap-2">
                                        <input type='radio' />
                                        <span className='text-gray-600'>Published</span>
                                    </div>
                                    <div className="flex gap-2">
                                        <input type='radio' />
                                        <span className='text-gray-600'>UnPublished</span>
                                    </div>
                                    <div className="flex gap-2">
                                        <input type='radio' />
                                        <span className='text-gray-600'>Trashed</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h2 className=' pb-1 text-gray-700'>Tags *</h2>
                        <div className="py-3 bg-white border">
                            <div className="flex flex-wrap gap-2 px-2">
                                {tags.map((tag, index) => (
                                    <div key={index} className="flex items-center bg-gray-200 px-2 py-1 rounded">
                                        <span>{tag}</span>
                                        <button onClick={() => removeTag(index)} className="ml-2 text-red-500">
                                            <RxCross2 />
                                        </button>
                                    </div>
                                ))}
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    className="flex-grow px-2 py-1 border-none rounded"
                                    placeholder="Add a tag & press enter"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h2 className='text-gray-700 w-[30%]'>Access *</h2>
                        <div className=" flex items-center bg-white border justify-start gap-4 px-2 py-4 mt-1">
                            <select className='w-full outline-none text-gray-500'>
                                <option className='text-gray-600'>Public</option>
                                <option className='text-gray-600'>Private</option>
                            </select>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h2 className='text-gray-700 w-[30%]'>Category *</h2>
                        <div className=" flex items-center bg-white border justify-start gap-4 px-2 py-4 mt-1">
                            <select className='w-full outline-none text-gray-500'>
                                <option className='text-gray-300'>Select a Category</option>
                                {
                                    departments?.map((d, i) => (
                                        <option className='text-gray-400'>{d.Name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="mt-5 bg-white border px-4">
                        <div className="flex items-center justify-start pt-4">
                            <div className=" flex items-center cursor-pointer text-sm justify-center px-4 py-[0.4rem] gap-1 border border-[#4f5af02f] text-[#0c39eddb] bg-[#4f5af01d]">
                                <FaRegImage />
                                <span>Featured Image</span>
                            </div>
                            <div className=" flex items-center text-sm cursor-pointer justify-center px-6 py-[0.4rem] gap-1 border border-l-0 text-[#545557ec] border-[#54555760]">
                                Full Image
                            </div>
                        </div>
                        <div className=" flex items-center flex-col h-[40vh] justify-start border border-dashed w-full gap-4 px-2 py-4 mt-4">
                            <img src={placeHolderImage} className='rounded-full opacity-20' alt="" />
                            <span className='text-xs mt-3 text-gray-500'>Add Image from Media Manager</span>
                        </div>
                        <div className="w-full mt-3 text-gray-400">
                            <AuthInput className='w-full mb-2 py-[0.60rem] px-3 text-sm mt-1 border-gray-200' label='Alt Text' placeholder='E.g. How to make a beautiful career !!' />
                        </div>
                        <div className="w-full mt-3 text-gray-400">
                            <AuthInput className='w-full mb-2 py-[0.60rem] px-3 text-sm mt-1 border-gray-200' label='Caption' placeholder='e.g Picture taken by Ansel Adams' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateArticle