import React from 'react';
import { useHistory } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa'; // Import icons for edit and delete
const DraftElement = ({ image, title, date, status, onEdit, onDelete }) => {
    return (
        <div className="flex justify-between items-center mt-2 gap-3 bg-gray-50 rounded-lg px-3 py-2 border border-gray-200 hover:shadow-sm transition-shadow">
            {/* Left Section: Image, Title, and Date */}
            <div className="flex items-center gap-2 flex-1">
                <img
                    className="h-11 w-12 rounded-lg border border-gray-200 object-cover"
                    src={image}
                    alt={title}
                />
                <div className="flex flex-col gap-1">
                    <span className="text-sm font-medium text-gray-800">{title}</span>
                    <span className="text-xs text-gray-500">{date}</span>
                </div>
            </div>

            {/* Middle Section: Current Status */}
            <span
                className={`text-xs font-semibold px-2 py-1 rounded-full ${status === 'draft'
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-gray-100 text-gray-800'
                    }`}
            >
                {status}
            </span>

            {/* Right Section: Edit and Delete Buttons */}
        </div>
    );
};

let draftList = [
    {
        ID: 3,
        status: 'draft',
        title: 'Exploring the Depths of Space Exploration',
        date: '2023-11-10',
        img: 'https://media.istockphoto.com/id/1177502660/photo/young-woman-reading-the-news-on-a-modern-tablet-computer-while-sitting-in-her-living-room.jpg?s=612x612&w=0&k=20&c=oEfXfMaKkgAVfshd7yk_bxGk2iQncWueLVlTL__gWWg='
    },
    {
        ID: 4,
        status: 'draft',
        title: 'The Rise of Artificial Intelligence',
        date: '2023-11-15',
        img: 'https://images.pexels.com/photos/7821682/pexels-photo-7821682.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        ID: 1,
        status: 'draft',
        title: 'The Future of Renewable Energy',
        img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMyYkoKMzp33jAm92IRkEXaoRIYjISmldt4A&s',
        date: '2023-11-01',
    },
    {
        ID: 2,
        status: 'draft',
        title: 'Top 10 Programming Languages in 2023',
        date: '2023-11-05',
        img: 'https://media.istockphoto.com/id/1177502660/photo/young-woman-reading-the-news-on-a-modern-tablet-computer-while-sitting-in-her-living-room.jpg?s=612x612&w=0&k=20&c=oEfXfMaKkgAVfshd7yk_bxGk2iQncWueLVlTL__gWWg='
    },
];

const Drafts = () => {
    const history = useHistory();

    // Get the three most recent drafts
    const recentDrafts = draftList.slice(-3);

    return (
        <div className="border min-h-[33vh] w-full bg-white rounded-lg py-3 px-1">
            <div className="w-full flex items-center justify-between mb-2 pb-2 px-4 border-b">
                <h3 className="text-lg text-gray-700">Drafts</h3>
                <button
                    onClick={() => history.push('/article-management')}
                    className="text-xs text-blue-600 hover:text-blue-800 focus:outline-none"
                >
                    See More
                </button>
            </div>
            <div className="mt-3 px-4">
                {recentDrafts.map((draft) => (
                    <DraftElement
                        key={draft.ID}
                        title={draft.title}
                        date={draft.date}
                        status={draft.status}
                        image={draft.img}
                    />
                ))}
            </div>
        </div>
    );
};

export default Drafts;