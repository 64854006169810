import React from 'react'

const Button = ({ handleClick, className, text, loading, ...rest }) => {
    return (
        <div className={className ? className : 'px-6 cursor-pointer py-[0.4rem] bg-blue-700 text-white rounded'} {...rest} onClick={handleClick}>
            {loading ? 'Loading...' : text}
        </div>
    )
}

export default Button